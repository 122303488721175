
export const addDocumentInStorage = (document) => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    documents.savedDocuments.push(document);
    localStorage.setItem("documents", JSON.stringify(documents));
};

export const getDocumentsInStorage = () => {
    const documents = JSON.parse(localStorage.getItem("documents"));
    return documents.savedDocuments;
}

export const getAudiobooksInStorage = () => {
    const audiobooks = JSON.parse(localStorage.getItem("audiobooks"));
    return audiobooks.savedAudiobooks;
}

export const addDocumentInStorageVideo = (video) => {
    const videos = JSON.parse(localStorage.getItem("videos"));
    videos.savedVideos.push(video);
    localStorage.setItem("videos", JSON.stringify(videos));
};

export const getDocumentsInStorageVideo = () => {
    const videos = JSON.parse(localStorage.getItem("videos"));
    return videos.savedVideos;
}

export const getDocumentsInStorageGeneration = () => {
    const documents = JSON.parse(localStorage.getItem("generations"));
    return documents.savedGenerations;
}

// Utility functions for localStorage handling
export const getAudioURLsFromLocalStorage = (fileID) => {
    const storedData = localStorage.getItem("audioURLsMap");
    if (storedData) {
        const audioMap = JSON.parse(storedData);
        return audioMap[fileID] || [];
    }
    return [];
};

export const updateAudioURLsInLocalStorage = (fileID, newURL) => {
    const storedData = localStorage.getItem("audioURLsMap");
    let audioMap = storedData ? JSON.parse(storedData) : {};
    const currentURLs = audioMap[fileID] || [];
    audioMap[fileID] = [...currentURLs, newURL];
    localStorage.setItem("audioURLsMap", JSON.stringify(audioMap));
};

export const clearAudioURLsInLocalStorage = (fileID) => {
    const storedData = localStorage.getItem("audioURLsMap");
    let audioMap = storedData ? JSON.parse(storedData) : {};
    delete audioMap[fileID];
    localStorage.setItem("audioURLsMap", JSON.stringify(audioMap));
}













