import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    VStack,
    HStack,
    Flex,
    Text,
    useColorModeValue,
    IconButton,
    Textarea,
    Button,
    Heading,
    UnorderedList,
    OrderedList,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
    useDisclosure,
    useToast, ListIcon, List, Divider, Icon, Badge, Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import {useDispatch, useSelector} from "react-redux";
import {MessageBubble} from "./MessageBubble";
import {useAuth} from "../../redux/AuthProvider";
import lightTheme from "../../utils/styles";
import {FaBars, FaCheckCircle} from "react-icons/fa";
import {AiOutlineArrowRight, AiOutlineSend} from "react-icons/ai";
import {RiCloseFill} from "react-icons/ri";
import {
    setAskContent, setAskImageContent,
    setCurrentChatInput, setFollowUpContent, setIsPricingModalOpen, toggleIsAskImageVisibleFalse,
    toggleIsAskVisibleFalse, toggleIsFollowUpVisibleFalse,
    toggleIsFollowUpVisibleTrue
} from "../../redux/reducers";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import {BiStopCircle} from "react-icons/bi";
import {markdownComponents, sampleTranscript} from "../../utils/constants";
import SignUpScreen1 from "../Modals/SignUp/SignUpScreen1";
import SignInScreen1 from "../Modals/SignIn/SignInScreen1";
import {CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import { GrChatOption } from "react-icons/gr";
import {FcComments} from "react-icons/fc";
import {initializeGeneration} from "../../utils/pdf-page-helpers";
import {useNavigate} from "react-router-dom";
import PricingScreen from "../Modals/Payments/PricingScreen";
import {useTranslation} from "react-i18next";
import {SuggestedQuestionsCarousel} from "./SuggestedQuestionsCarousel";
import {useServerIP} from "../../redux/ServerIPContext";
import TranscriptViewer from "./TranscriptViewer";

export const Transcript = ({ theDocument, handleNewMessage, handleChatResponseNoSelection, sessionToken, socketID, socket,
                         stopClaudeResponse, suggestedQuestions, audiobookOutline, fileID, userID }) => {
    const lastMessageRef = useRef(null);
    const userBgColor = useColorModeValue("#000", "#fff");
    const claudeBgColor = useColorModeValue(lightTheme.colors.lightGray, "#2e93ea");
    const claudeColor = useColorModeValue("#000000", "#E2E8F0");
    const currentChatInput = useSelector(state => state.settings.currentChatInput);

    const { isTyping, setIsTyping } = useAuth();
    const {isThinking, setIsThinking} = useAuth();
    const documents = useSelector(state => state.documents.savedDocuments);
    // const currentMessages = documents.find(doc => doc.id === theDocument.id).messages;
    const [isInputFocused, setIsInputFocused] = useState(false);
    const dispatch = useDispatch();
    const textareaRef = useRef(null);
    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const toast = useToast();
    const auth = useAuth();
    const navigate = useNavigate();
    const isProPlan = useSelector((state) => state.settings.isProPlan);
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const {t} = useTranslation();
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const [selectedModel, setSelectedModel] = useState("default");

    // const modifiedHandleNewMessage = () => {
    //     // if (currentChatInput === 'pricing') {
    //     //     dispatch(setIsPricingModalOpen(true));
    //     //     dispatch(setCurrentChatInput(''));
    //     //     if (textareaRef && textareaRef.current) {
    //     //         textareaRef.current.style.height = '3.5rem';
    //     //     }
    //     //     return;
    //     // }
    //
    //     if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isChatExceeded") === "true") {
    //         toast({
    //             title: t("(Free Plan) Chat Limit Exceeded!"),
    //             status: "info",
    //             duration: 9000,
    //             isClosable: true,
    //             position: "top",
    //         });
    //         dispatch(setIsPricingModalOpen(true));
    //         dispatch(setCurrentChatInput(''));
    //         if (textareaRef && textareaRef.current) {
    //             textareaRef.current.style.height = '3.5rem';
    //         }
    //         return;
    //     }
    //
    //
    //     if (currentMessages && currentMessages.length >= 3 && localStorage.getItem("userID") === null) {
    //         toast({
    //             title: t("You've reached your chat limit in guest mode!"),
    //             description: t("Please sign up to continue chatting."),
    //             status: "info",
    //             duration: 9000,
    //             isClosable: true,
    //             position: "top",
    //         });
    //         onOpenSignUpScreen1();
    //         const chatInput = currentChatInput;
    //         dispatch(setCurrentChatInput(''));
    //         if (textareaRef && textareaRef.current) {
    //             textareaRef.current.style.height = '3.5rem';
    //         }
    //         return;
    //     }
    //     const chatInput = currentChatInput;
    //     dispatch(setCurrentChatInput(''));
    //     handleNewMessage(chatInput, selectedModel);
    //     dispatch(setCurrentChatInput(''));
    //     if (textareaRef && textareaRef.current) {
    //         textareaRef.current.style.height = '3.5rem';
    //     }
    // };

    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter' && !e.shiftKey) {
    //         e.preventDefault();
    //         if (currentChatInput !== "" && !isTyping && !isThinking) {
    //             modifiedHandleNewMessage();
    //         }
    //     }
    // };

    // useEffect(() => {
    //     const scrollMessageIntoView = () => {
    //         if (lastMessageRef.current) {
    //             // console.log('Height of last message:', lastMessageRef.current.offsetHeight);
    //             if (lastMessageRef.current.offsetHeight < 500) {
    //                 lastMessageRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
    //             }
    //         }
    //     };
    //     scrollMessageIntoView();
    //
    // }, [currentMessages]);

    useEffect(() => {
        const scrollMessageIntoView = () => {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        };
        scrollMessageIntoView();

    }, []);

    const placeholders = ["Write a 500 word discussion post using 3 readings",
        "Write a 600 word essay using this source", "Summarize 5 documents in 400 words"];
    const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true); // State to manage fade in and out

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
                setCurrentPlaceholder(placeholders[index]);
                setFade(true);
            }, 200); // Half the interval time to allow for fade in and fade out
        }, 1555);

        return () => clearInterval(intervalId);
    }, [index]);

    const placeholdersInput = [t("Enter your prompt here..."),
        t("Shift + Enter to add a new line")];
    const [currentPlaceholderInput, setCurrentPlaceholderInput] = useState(placeholdersInput[0]);
    const [indexInput, setIndexInput] = useState(0);
    const [fadeInput, setFadeInput] = useState(true); // State to manage fade in and out

    useEffect(() => {
        const intervalIdInput = setInterval(() => {
            setFadeInput(false); // Fade out the current placeholder

            setTimeout(() => {
                // After fade out, change the placeholder and fade it in
                setIndexInput((prevIndex) => (prevIndex + 1) % placeholdersInput.length);
                setCurrentPlaceholderInput(placeholdersInput[indexInput]);
                setFadeInput(true);
            }, 750); // Half the interval time to allow for fade in and fade out
        }, 1500);

        return () => clearInterval(intervalIdInput);
    }, [indexInput]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" p={0} boxShadow="sm" borderRadius="md"
             border="0px" borderColor="gray.300" h="100%" maxW="100%">
            <Box h={'100%'} overflowY="auto" bg={'red'}>
                <Flex justifyContent={'flex-start'} flexDirection={'column'} height={'100%'}>

                    {/*here is where you should code the transcript view and the expandable and shrinkable outline view*/}
                    <TranscriptViewer audiobookOutline={audiobookOutline} fileID={fileID} userID={userID} socketID={socketID} socket={socket} />

                </Flex>
            </Box>
            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
        </Box>
    );
};
