import axios from "axios";
import {SERVER_IP} from "./constants";


export const handleSignUp = async (email, userName, firstName, lastName, serverIP) => {
    try {
        const response = await axios.post(serverIP + '/signup', {
            "email" : email,
            "username" : userName,
            "first_name" : firstName,
            "last_name" : lastName,
        });
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 400) {
            return {
                "status" : 400,
            }
        } else if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        }
    } catch (error) {
        console.log("/signup error", error);
        return null;
    }
}

export const handleSignIn = async (email, serverIP) => {
    try {
        const response = await axios.post(serverIP + '/signin', {
            "email" : email,
        });
        if (response.data.status === 200) {
            return {
                "status" : 200,
            }
        } else if (response.data.status === 404) {
            return {
                "status" : 404,
            }
        }
    } catch (error) {
        console.log("/signin error", error);
        return null;
    }
}

export const handleCheckVerificationCode = async (email, code_provided, request_type, serverIP) => {
    let requestBody = {
        "email" : email,
        "code_provided" : code_provided,
        "request_type" : request_type,
        "browser_fingerprint" : localStorage.getItem("fingerprint"),
    }

    if (localStorage.getItem("referralCode") !== null && request_type === 'signup') {
        requestBody = {
            "email" : email,
            "code_provided" : code_provided,
            "request_type" : request_type,
            "browser_fingerprint" : localStorage.getItem("fingerprint"),
            "referral_code": localStorage.getItem("referralCode")
        }
    }

    try {
        const response = await axios.post(serverIP + '/check_verification_code', requestBody);
        console.log("response for check veri code", response)
        if (response.data.status === 401) {
            return {
                "status" : 401,
            }
        } else if (response.data.status === 200) {
            if (request_type === "signup") {
                return {
                    "status" : 200,
                    "userID" : response.data.message.userID,
                    "email" : response.data.message.email,
                    "firstName" : response.data.message.first_name,
                    "lastName" : response.data.message.last_name,
                }
            } else {
                return {
                    "status" : 200,
                    "userID" : response.data.message.userID,
                    "email" : response.data.message.email,
                    "firstName" : response.data.message.first_name,
                    "lastName" : response.data.message.last_name,
                }
            }
        }
    } catch (error) {
        console.log("/check_verification_code error", error);
        return null;
    }
}

