import React, { useState } from "react";
import {
    Box,
    Flex,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useColorModeValue,
    IconButton, Text,
    keyframes, useDisclosure, useToast
} from '@chakra-ui/react';
import { Chat } from './Chat';
import { Highlights } from './Highlights';
import { useDispatch, useSelector } from "react-redux";
import {
    addMessageInDocument,
    addMessageInDocumentGeneration,
    addToCurrentChatResponseGeneration,
    appendToMessageInDocumentGeneration,
    removeMessageInDocumentGeneration,
    resetCurrentChatResponse,
    resetCurrentChatResponseGeneration,
    setAskContent,
    setCurrentChatClaudeIDGeneration,
    setCurrentChatInput,
    setCurrentChatInputGeneration,
    setCurrentChatQuestionGeneration,
    setCurrentChatSelectionTextGeneration,
    setCurrentChatTypeGeneration,
    setCurrentChatUserIDGeneration,
    setCurrentGenerationProgress,
    setCurrentGenerationSessionToken,
    setFollowUpContent,
    setIsPricingModalOpen,
    setWhichTab,
    toggleIsAskVisibleFalse,
    toggleIsChatVisible,
    toggleIsFollowUpVisibleFalse,
    updateMessageInDocumentGeneration
} from "../../redux/reducers";
import { v4 as uuidv4 } from 'uuid';
import {useAuth} from "../../redux/AuthProvider";
import {AiOutlineZoomOut} from "react-icons/ai";
import {FiChevronUp, FiMinimize2} from "react-icons/fi";
import lightTheme from "../../utils/styles";
import {CustomIconButton, CustomIconButtonWhite} from "../buttons/CustomIconButton";
import {Generation} from "./Generation";
import SignInScreen1 from "../Modals/SignIn/SignInScreen1";
import GeneratePromptScreen1 from "../Modals/Generation/GeneratePromptScreen1";
import {getClaudeResponse, postChatPair} from "../../utils/api";
import {createGenerationSession, generateContent} from "../../utils/pdf-page-helpers";
import axios from "axios";
import {SERVER_IP} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {GenerationOld} from "./GenerationOld";
import {useServerIP} from "../../redux/ServerIPContext";

// handleChatResponseNoSelection = async (type, pageNumber, sessionToken, socketID, question)
export function SidebarGenerationOld({ documentGeneration, sessionTokenGeneration, width, socketIDGeneration, socketGeneration}) {
    const { serverIPs, setServerIPs, isDev } = useServerIP();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const currentChatUserIDGeneration = useSelector(state => state.settings.currentChatUserIDGeneration);
    const currentChatTypeGeneration = useSelector(state => state.settings.currentChatTypeGeneration);
    const currentChatSelectionTextGeneration = useSelector(state => state.settings.currentChatSelectionTextGeneration);
    const currentChatQuestionGeneration = useSelector(state => state.settings.currentChatQuestionGeneration);
    const currentChatClaudeIDGeneration = useSelector(state => state.settings.currentChatClaudeIDGeneration);
    const currentChatResponseGeneration = useSelector(state => state.settings.currentChatResponseGeneration);

    const { isTypingGeneration, setIsTypingGeneration } = useAuth();
    const {isThinkingGeneration, setIsThinkingGeneration} = useAuth();
    const toast = useToast();
    const auth = useAuth();
    const { isOpen: isOpenGeneratePromptScreen1, onOpen: onOpenGeneratePromptScreen1, onClose: onCloseGeneratePromptScreen1 } = useDisclosure();
    const currentGenerationSessionToken = useSelector(state => state.settings.currentGenerationSessionToken);
    const [intervalHook, setIntervalHook] = useState(0)

    // // type, message_responding_to, follow_up_question, sessionToken, socketID
    // const handleNewMessageGeneration = (chatInputGeneration) => {
    //     handleChatResponseNoSelection('general_question', null, sessionTokenGeneration, socketIDGeneration, chatInputGeneration);
    //     dispatch(setCurrentChatInputGeneration(''));
    // };

    // finish adding in the true and false for isThinking and isTyping later
    const startGeneration = async (prompt, sliderValue, shouldReferenceSources) => {
        const { userGenerationID, claudeGenerationID } = handleMessageCreation("general_generation", prompt, sliderValue, shouldReferenceSources);
        const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111";

        dispatch(resetCurrentChatResponseGeneration());
        dispatch(setCurrentChatUserIDGeneration(userGenerationID));
        dispatch(setCurrentChatClaudeIDGeneration(claudeGenerationID));
        dispatch(setCurrentChatQuestionGeneration(prompt));
        dispatch(setCurrentChatTypeGeneration("general_generation"));

        setIsThinkingGeneration(true);
        setIsTypingGeneration(false);
        let sessionToken = currentGenerationSessionToken;
        if (sessionToken === "") {
            const res = await createGenerationSession(documentGeneration.id, userID, serverIPs.SERVER_IP);
            sessionToken = res.generationSessionToken;
            dispatch(setCurrentGenerationSessionToken(sessionToken));
        }

        try {
            const res = await generateContent(userID, sessionToken, prompt, sliderValue, shouldReferenceSources, socketIDGeneration, toast, serverIPs.SERVER_IP);
            if (res.status === 200 || res.status === 201 || res.status === 202) {
                try {
                    await checkGenerationProgress(userID, sessionToken, claudeGenerationID, res.celeryTaskID);
                } catch (error) {
                    socketGeneration.off('receive_message');
                    console.log("/generate error", error);
                    return null;
                }
            }
            else if (res.status === 402) {
                console.log("pricing_modal_shown")
                setIsTypingGeneration(false);
                setIsThinkingGeneration(false);
                socketGeneration.off('receive_message');
                dispatch(removeMessageInDocumentGeneration({ messageID: userGenerationID, documentID: documentGeneration.id }));
                dispatch(removeMessageInDocumentGeneration({ messageID: claudeGenerationID, documentID: documentGeneration.id }));
                dispatch(setIsPricingModalOpen(true));
                localStorage.setItem("isChatExceeded", "true");
            }
        } catch (error) {
            socketGeneration.off('receive_message');
            console.error("Error getting Claude response:", error);
        }
    };

    const checkGenerationProgress = async (userID, sessionToken, claudeGenerationID, celeryTaskID) => {
        socketGeneration.on('receive_message', function(data) {
            // console.log("GEN message from Claude:", data.message)
            if (data.message.includes("<progress>")) {
                const progressWithTags = data.message; // Assuming this variable contains something like "<progress>0_1</progress>"
                const progressWithoutTags = progressWithTags.replace('<progress>', '').replace('</progress>', ''); // Remove <progress> and </progress> tags
                const progressParts = progressWithoutTags.split('_');

                console.log(progressParts)
                const currentStep = parseInt(progressParts[0], 10);
                const totalSteps = parseInt(progressParts[1], 10);

                dispatch(setCurrentGenerationProgress([currentStep, totalSteps]));
                // if (currentStep === 0) {
                //     console.log("currentStep === 0")
                //     progressPercentage = 10; // Setting to 10% if current step is 0
                //     dispatch(setCurrentGenerationProgress(progressPercentage));
                // } else {
                //     console.log("currentStep", currentStep)
                //     console.log("totalSteps", totalSteps)
                //     progressPercentage = (currentStep / totalSteps) * 100; // Calculating the fraction as percentage
                //     dispatch(setCurrentGenerationProgress(progressPercentage));
                // }

                // <attempt_1_of_4>
                // <attempt_2_of_4>
                // <the_end>
            } else if (data.message.includes("<the_end>")) {
                setIsTypingGeneration(false);
                setIsThinkingGeneration(false);
                socketGeneration.off('receive_message');
            }
            else {
                dispatch(setCurrentGenerationProgress(-1));
                setIsThinkingGeneration(false);
                setIsTypingGeneration(true);
                dispatch(addToCurrentChatResponseGeneration(data.message));
                dispatch(appendToMessageInDocumentGeneration({ messageID: claudeGenerationID, type: "general_generation", user: 'Claude',
                    selection_text: "", question: "", response: data.message, documentID: documentGeneration.id }));
            }
        });
        const interval = setInterval(async () => {
            const response = await axios.post(serverIPs.SERVER_IP + '/pdf/generation_progress', {
                "user_id": userID.toString(),
                "generation_session_token": sessionToken.toString(),
                "task_id": celeryTaskID.toString(),
            });
            console.log("response from /pdf/generation_progress", response);
            if (response.data.status === 200) {
                console.log("RETURNED CONTENT", response.data.message.generation_content);  // IMPORTANT LINE
                const responseText = response.data.message.generation_content;

                // setIsTypingGeneration(false);
                // setIsThinkingGeneration(false);
                // socketGeneration.off('receive_message');
                // dispatch(updateMessageInDocumentGeneration({ messageID: claudeGenerationID, response: responseText,
                //     documentID: documentGeneration.id }));
                clearInterval(interval);
                // else {
                //     if (!responseText === undefined) {
                //         dispatch(updateMessageInDocumentGeneration({ messageID: claudeGenerationID, response: "",
                //             documentID: documentGeneration.id }));
                //     }
                // }
            } else if (response.data.status === 205) {
                console.log("still processing");
            } else if (response.data.status === 401) {
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: null,
                    isClosable: true,
                });
                clearInterval(interval);
                setIsTypingGeneration(false);
                setIsThinkingGeneration(false);
                socketGeneration.off('receive_message');
            }
        }, 4000);
        setIntervalHook(interval);
    };

    const handleMessageCreation = (type, question, wordCount, shouldReferenceSources) => {
        const userIDHere = uuidv4();
        const claudeID = uuidv4();
        socketGeneration.off('receive_message');
        const userMessage = {
            id: userIDHere,
            type: type,
            question: question,
            user: 'User',
            word_count: wordCount,
            should_reference_sources: shouldReferenceSources,
        };
        const claudeMessage = {
            id: claudeID,
            type: type,
            // question: question,
            user: 'Claude',
            // word_count: wordCount,
            // should_reference_sources: shouldReferenceSources,
            response: ""
        };
        dispatch(addMessageInDocumentGeneration({ messageToAdd: userMessage, documentID: documentGeneration.id }));
        dispatch(addMessageInDocumentGeneration({ messageToAdd: claudeMessage, documentID: documentGeneration.id }));
        return { userGenerationID: userIDHere, claudeGenerationID: claudeID };
    }

    const stopClaudeResponse = () => {
        setIsThinkingGeneration(false);
        setIsTypingGeneration(false);
        socketGeneration.off('receive_message');
        postChatPair([
            {
                id: currentChatUserIDGeneration, type: currentChatTypeGeneration, user: 'User',
                selection_text: currentChatSelectionTextGeneration, question: currentChatQuestionGeneration
            },
            {
                id: currentChatClaudeIDGeneration, type: currentChatTypeGeneration, user: 'Claude',
                selection_text: currentChatSelectionTextGeneration, question: currentChatQuestionGeneration,
                response: currentChatResponseGeneration
            }
        ], localStorage.getItem("userID"),  documentGeneration.id, auth.isLoggedIn, serverIPs.SERVER_IP).then(r =>{
            dispatch(resetCurrentChatResponseGeneration())
        });
    }

    return (
        <Box width={`${width}%`} height="100%" display="flex" flexDirection="column">
            <GenerationOld theDocumentGeneration={documentGeneration}
                        sessionTokenGeneration={sessionTokenGeneration} socketIDGeneration={socketIDGeneration}
                        stopClaudeResponseGeneration={stopClaudeResponse} startGeneration={startGeneration}/>
            <GeneratePromptScreen1 isOpenGeneratePromptScreen1={isOpenGeneratePromptScreen1} onCloseGeneratePromptScreen1={onCloseGeneratePromptScreen1}/>
        </Box>
    );
}
