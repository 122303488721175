// audioUtils.js

// Fetch a WAV file from URL and return an ArrayBuffer.
export async function fetchWavBuffer(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsArrayBuffer(blob);
    });
}

// --- Helper functions for creating a WAV header (from the StackOverflow example) ---

export function getWavHeader(options) {
    const numFrames = options.numFrames;
    const numChannels = options.numChannels || 2;
    const sampleRate = options.sampleRate || 44100;
    const bytesPerSample = options.isFloat ? 4 : 2;
    const format = options.isFloat ? 3 : 1;
    const blockAlign = numChannels * bytesPerSample;
    const byteRate = sampleRate * blockAlign;
    const dataSize = numFrames * blockAlign;
    const buffer = new ArrayBuffer(44);
    const dv = new DataView(buffer);
    let p = 0;
    function writeString(s) {
        for (let i = 0; i < s.length; i++) {
            dv.setUint8(p + i, s.charCodeAt(i));
        }
        p += s.length;
    }
    function writeUint32(d) {
        dv.setUint32(p, d, true);
        p += 4;
    }
    function writeUint16(d) {
        dv.setUint16(p, d, true);
        p += 2;
    }
    writeString("RIFF");
    writeUint32(dataSize + 36);
    writeString("WAVE");
    writeString("fmt ");
    writeUint32(16);
    writeUint16(format);
    writeUint16(numChannels);
    writeUint32(sampleRate);
    writeUint32(byteRate);
    writeUint16(blockAlign);
    writeUint16(bytesPerSample * 8);
    writeString("data");
    writeUint32(dataSize);
    return new Uint8Array(buffer);
}

export function getWavBytes(buffer, options) {
    const type = options.isFloat ? Float32Array : Uint16Array;
    const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT;
    const headerBytes = getWavHeader({ ...options, numFrames });
    const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);
    wavBytes.set(headerBytes, 0);
    wavBytes.set(new Uint8Array(buffer), headerBytes.length);
    return wavBytes;
}

// --- Read the WAV header to extract info from the first file ---
export function getAudioData() {
    function WavHeader() {
        this.dataOffset = 0;
        this.dataLen = 0;
        this.channels = 0;
        this.sampleRate = 0;
    }
    function fourccToInt(fourcc) {
        return fourcc.charCodeAt(0) << 24 |
            fourcc.charCodeAt(1) << 16 |
            fourcc.charCodeAt(2) << 8  |
            fourcc.charCodeAt(3);
    }
    WavHeader.RIFF = fourccToInt("RIFF");
    WavHeader.WAVE = fourccToInt("WAVE");
    WavHeader.fmt_ = fourccToInt("fmt ");
    WavHeader.data = fourccToInt("data");
    WavHeader.readHeader = function(dataView) {
        var w = new WavHeader();
        var header = dataView.getUint32(0, false);
        if (WavHeader.RIFF !== header) {
            return;
        }
        var fileLen = dataView.getUint32(4, true);
        if (WavHeader.WAVE !== dataView.getUint32(8, false)) {
            return;
        }
        if (WavHeader.fmt_ !== dataView.getUint32(12, false)) {
            return;
        }
        var fmtLen = dataView.getUint32(16, true);
        var pos = 16 + 4;
        switch (fmtLen) {
            case 16:
            case 18:
                w.channels = dataView.getUint16(pos + 2, true);
                w.sampleRate = dataView.getUint32(pos + 4, true);
                break;
            default:
                throw 'extended fmt chunk not implemented';
        }
        pos += fmtLen;
        var dataTag = WavHeader.data;
        var len = 0;
        while (dataTag !== header) {
            header = dataView.getUint32(pos, false);
            len = dataView.getUint32(pos + 4, true);
            if (dataTag === header) {
                break;
            }
            pos += (len + 8);
        }
        w.dataLen = len;
        w.dataOffset = pos + 8;
        return w;
    };
    getAudioData.WavHeader = WavHeader;
}
getAudioData();

// --- Combine two WAV ArrayBuffers (by concatenating their bytes and fixing header) ---
export async function combineWavBuffers(buffer1, buffer2) {
    // If buffer1 is empty or too small to contain a valid header, simply return buffer2.
    if (buffer1.byteLength < 44) {
        console.warn("Buffer1 is too small for a valid WAV header. Using buffer2 as the combined result.");
        return new Promise((resolve) => {
            const arrBytesFinal = new Uint8Array(buffer2);
            const myBlob = new Blob([arrBytesFinal], { type: 'audio/wav; codecs=MS_PCM' });
            const readerBlob = new FileReader();
            readerBlob.onloadend = function() {
                resolve({ dataUrl: readerBlob.result, combinedBuffer: arrBytesFinal.buffer });
            };
            readerBlob.readAsDataURL(myBlob);
        });
    }

    // Combine the bytes of the two WAV buffers.
    const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);

    // Try to read the header from buffer1. If that fails, fallback to default values.
    let audioData;
    try {
        audioData = getAudioData.WavHeader.readHeader(new DataView(buffer1));
    } catch (error) {
        console.warn("Error reading WAV header from buffer1; using default values", error);
        audioData = { channels: 2, sampleRate: 44100 };
    }

    // Generate new WAV bytes with corrected header for the combined data.
    const arrBytesFinal = getWavBytes(tmp.buffer, {
        isFloat: false,
        numChannels: audioData.channels,
        sampleRate: audioData.sampleRate,
    });

    // Create a Blob from the combined bytes.
    const myBlob = new Blob([arrBytesFinal], { type: 'audio/wav; codecs=MS_PCM' });
    return new Promise((resolve) => {
        const readerBlob = new FileReader();
        readerBlob.onloadend = function() {
            // Provide a copy of the combined buffer for subsequent appends.
            resolve({ dataUrl: readerBlob.result, combinedBuffer: arrBytesFinal.slice().buffer });
        };
        readerBlob.readAsDataURL(myBlob);
    });
}

