import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack,
    useToast,
    Textarea,
    ModalFooter,
    List,
    ListItem,
    ListIcon,
    Checkbox,
    Avatar,
    Spacer,
    Icon,
    useDisclosure,
    Progress
} from "@chakra-ui/react";
import {
    addDocument,
    appendFilesInDocumentGeneration,
    removeDocument,
    setCeleryTaskID,
    setCurrentChatInput,
    setFilesInDocumentGeneration,
    setFilesUploadingInDocumentGeneration, setIsPricingModalOpen,
    setIsScanActuallyInProgress,
    setIsUploadLoading,
    setIsUploadSourcesModalOpen,
    setMessagesInDocumentGeneration,
    setScanEstimatedSeconds,
    setScanFileID,
    setScanInProgress
} from "../../../redux/reducers";
import {FaCheckCircle} from "react-icons/fa";
import lightTheme from "../../../utils/styles";
import {addDocumentInStorage, getDocumentsInStorage} from "../../../utils/local-storage-helpers";
import {useDropzone} from "react-dropzone";
import {motion} from "framer-motion";
import {CloseIcon} from "@chakra-ui/icons";
import {ImFilePdf} from "react-icons/im";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {SERVER_IP} from "../../../utils/constants";
import {useAuth} from "../../../redux/AuthProvider";
import SignUpScreen1 from "../SignUp/SignUpScreen1";
import SignInScreen1 from "../SignIn/SignInScreen1";
import ScanModal1 from "../Scans/ScanModal1";
import ScanModalIntro1 from "../Scans/ScanModalIntro1";
import { v4 as uuidv4 } from 'uuid';
import {getSessionTokenGeneration} from "../../../utils/pdf-page-helpers";
import {useTranslation} from "react-i18next";
import {SourceLimitBar} from "./SourceLimitBar";
import {getMultidoc} from "../../../utils/multi-doc-helpers";
import { setIsViewSourcesModalOpen } from "../../../redux/reducers";
import { setCurrentGeneratePDF, setCurrentGeneratePDFPage, setIsGeneratePDFVisible } from "../../../redux/reducers";
import { addActiveSourcesMultidoc } from "../../../utils/multi-doc-helpers";
import {useServerIP} from "../../../redux/ServerIPContext";
const MotionBox = motion(Box);

const GeneratePromptScreen2 = ({ isOpenGeneratePromptScreen2, onCloseGeneratePromptScreen2, generation, sessionTokenGeneration }) => {
    const { serverIPs, setServerIPs, isDev } = useServerIP();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const fileInput = useRef();
    const dispatch = useDispatch();
    const isProPlan = useSelector((state) => state.settings.isProPlan);
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const {t} = useTranslation();

    const { isOpen: isOpenSignUpScreen1, onOpen: onOpenSignUpScreen1, onClose: onCloseSignUpScreen1 } = useDisclosure();
    const { isOpen: isOpenSignInScreen1, onOpen: onOpenSignInScreen1, onClose: onCloseSignInScreen1 } = useDisclosure();
    const { isOpen: isOpenScanModal1, onOpen: onOpenScanModal1, onClose: onCloseScanModal1 } = useDisclosure();
    const { isOpen: isOpenScanModalIntro1, onOpen: onOpenScanModalIntro1, onClose: onCloseScanModalIntro1 } = useDisclosure();

    const auth = useAuth();
    const [intervals, setIntervals] = useState({});
    const generations = useSelector(state => state.documents.savedGenerations);
    const currentGenerationSessionToken = useSelector(state => state.settings.currentGenerationSessionToken);
    const isGeneratePDFVisible = useSelector(state => state.settings.isGeneratePDFVisible);
    const currentGeneratePDF = useSelector(state => state.settings.currentGeneratePDF);
    const currentGeneratePDFPage = useSelector(state => state.settings.currentGeneratePDFPage);
    const [files, setFiles] = useState([]);

    const uploadSources = async () => {
        setIsLoading(true)
        // first, append a new "url" field to each file object. And then only keep the name, id, and url fields
        // const filesToUpload = files.map(file => {
        //     const newFile = { ...file };
        //     newFile.url = URL.createObjectURL(file.file);
        //     delete newFile.file;
        //     delete newFile.type;
        //     delete newFile.size;
        //     delete newFile.progress;
        //     delete newFile.status;
        //     return newFile;
        // });
        //
        // dispatch(appendFilesInDocumentGeneration({ documentID: generation.id, newFiles: files }));
        const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
        const start = new Date().getTime() / 1000;

        const response = await getMultidoc(generation.id, userID, serverIPs.SERVER_IP_MULTIDOC);
        const end1 = new Date().getTime() / 1000;
        const duration1 = end1 - start;
        console.log("DURATION OF GET MULTIDOC AFTER CLICKING UPLOAD", duration1);
        if (response) {
            console.log("RESPONSE FROM GET MULTIDOC", response);
            if (response.files) {
                const files = response.files.map((file) => {
                    return {
                        id: file.file_id,
                        name: file.file_name,
                        url: file.url,
                        // isActive: true,
                        isActive: generation.files.find(f => f.id === file.file_id) !== undefined ? generation.files.find(f => f.id === file.file_id).isActive : true
                    }
                });
                addActiveSourcesMultidoc(sessionTokenGeneration, localStorage.getItem("userID"),
                                files.filter(file => file.isActive).map((file) => file.id), serverIPs.SERVER_IP_MULTIDOC).then(r => {
                                    const end2 = new Date().getTime() / 1000;
                                    const duration2 = end2 - start;
                                    console.log("DURATION OF ADD ACTIVE AFTER CLICKING UPLOAD", duration2);
                                    setIsLoading(false)
                                });
                dispatch(setFilesInDocumentGeneration({documentID: generation.id, newFiles: files}));
                if (!isGeneratePDFVisible) {
                    dispatch(setCurrentGeneratePDF(files[0].url));
                    dispatch(setCurrentGeneratePDFPage(1));
                    dispatch(setIsGeneratePDFVisible(true));
                }
            }
        }

        onCloseGeneratePromptScreen2();



        dispatch(setIsUploadSourcesModalOpen(false));
        dispatch(setIsViewSourcesModalOpen(true));
        setFiles([]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        // console.log("First ACCEPTED FILE", acceptedFiles[0])
        const newFiles = acceptedFiles.map(file => ({
            id: uuidv4(),
            file: file,
            type: file.type,
            name: file.name,
            size: file.size,
            progress: 0,
            status: 'uploading' // could be 'uploading', 'completed', 'error'
        }));

        // const startIndex = filesUploading.length;
        // newFiles.forEach((fileObj, index) => {
        //     handleFileUpload(fileObj, startIndex + index);
        // });
        // const filesToSet = [...filesUploading, ...newFiles];
        // dispatch(setFilesUploadingInDocumentGeneration({ documentID: generation.id, newFiles: filesToSet }));

        setFiles(prevFiles => {
            // Calculate the starting index for the new files
            const startIndex = prevFiles.length;

            // Schedule uploads for each new file
            newFiles.forEach((fileObj, index) => {
                handleFileUpload(fileObj, startIndex + index);
            });

            return [...prevFiles, ...newFiles];
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: true,
    });

    const closeModal = async () => {
        console.log("CLIKCED THE X BUTTON");
        if (canCloseModal() && files.length > 0) {
            const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"

            const response = await getMultidoc(generation.id, userID, serverIPs.SERVER_IP_MULTIDOC);
            if (response) {
                console.log("RESPONSE FROM GET MULTIDOC", response);
                if (response.files) {
                    const files = response.files.map((file) => {
                        return {
                            id: file.file_id,
                            name: file.file_name,
                            url: file.url,
                            // isActive: true,
                            isActive: generation.files.find(f => f.id === file.file_id) !== undefined ? generation.files.find(f => f.id === file.file_id).isActive : true
                        }
                    });
                    addActiveSourcesMultidoc(sessionTokenGeneration, localStorage.getItem("userID"),
                                    files.filter(file => file.isActive).map((file) => file.id), serverIPs.SERVER_IP_MULTIDOC).then(r => {});
                    dispatch(setFilesInDocumentGeneration({documentID: generation.id, newFiles: files}));
                    if (!isGeneratePDFVisible) {
                        dispatch(setCurrentGeneratePDF(files[0].url));
                        dispatch(setCurrentGeneratePDFPage(1));
                        dispatch(setIsGeneratePDFVisible(true));
                    }
                }
            }
            onCloseGeneratePromptScreen2();
            dispatch(setIsUploadSourcesModalOpen(false));
            dispatch(setIsViewSourcesModalOpen(true));
            setFiles([]);
        }
        else if (files.length === 0) {
            onCloseGeneratePromptScreen2();
            dispatch(setIsUploadSourcesModalOpen(false));
            setFiles([]);
        }
        else {
            toast({
                title: t('Processing...'),
                description: t('Please wait until all files are uploaded.'),
                status: "info",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    const canCloseModal = () => {
        // Check if any files are in 'ocring' or 'uploading' status
        const isProcessing = files.some(file => file.status === 'ocring' || file.status === 'uploading');
        return !isProcessing; // Return true if no files are processing, false otherwise
    };

    const startDocumentProcessing = async (scanFileID, fileIndex) => {
        try {
            const response = await axios.post(serverIPs.SERVER_IP_MULTIDOC + '/pdf/ocr', {
                user_id: localStorage.getItem('userID') !== null ? localStorage.getItem('userID') : "11111111-1111-1111-1111-111111111111",
                file_id: scanFileID,
                "ocr_type": "multidoc"
            });
            console.log("response from SERVER_IP_MULTIDOC /pdf/ocr", response)
            if (response.data.status === 200) {
                const start = new Date().getTime() / 1000;
                // setIsOpenScanModal2(true);
                // onCloseScanModal1();
                // setIsLoading(false)
                dispatch(setCeleryTaskID(response.data.message.celery_task_id))
                // dispatch(setIsScanActuallyInProgress(true))

                // start polling another endpoint to check if the document is ready
                const interval = setInterval(async () => {
                    const response = await axios.post(serverIPs.SERVER_IP_MULTIDOC + '/pdf/ocr_progress', {
                        user_id: localStorage.getItem('userID') !== null ? localStorage.getItem('userID') : "11111111-1111-1111-1111-111111111111",
                        file_id: scanFileID
                    });
                    console.log("response from SERVER_IP_MULTIDOC /pdf/ocr_progress", response)
                    if (response.data.status === 200) {
                        setFiles(files => files.map((f, i) => f.id === scanFileID ? { ...f, status: 'completed' } : f));
                        setFiles(files => files.map((f, i) => f.id === scanFileID ? { ...f, progress: 0 } : f));
                        // const updatedFilesUploading = filesUploading.map((f, i) => i === fileIndex ? { ...f, status: 'completed',
                        // progress: 0} : f);
                        // dispatch(setFilesUploadingInDocumentGeneration({ documentID: generation.id, newFiles: updatedFilesUploading }));

                        const end = new Date().getTime() / 1000;
                        const duration = end - start;
                        console.log("DURATION OF SCAN", duration)

                        // addActiveSourcesMultidoc(sessionTokenGeneration, localStorage.getItem("userID"),
                        //         [scanFileID]);

                        clearInterval(interval);
                        setIntervals(prevIntervals => {
                            const newIntervals = { ...prevIntervals };
                            delete newIntervals[scanFileID];
                            return newIntervals;
                        });
                    } else if (response.data.status === 205) {
                        console.log("still processing")
                        // set the progress of the file to 5% more than it was before. This is a hacky way to make the progress bar move
                        // However, make sure the progress never goes above 99%. So go from 95% to 99% then stop if it ever gets there
                        // console.log("ocr file", files.find(f => f.id === scanFileID))
                        // const estimatedTimeOCR = files.find(f => f.id === scanFileID).estimatedTimeOCR;
                        // let increment = 5;
                        // if (estimatedTimeOCR) {
                        //     increment = 5 / (estimatedTimeOCR / 100);
                        // }
                        // setFiles(files => files.map((f, i) => f.id === scanFileID
                        //     ? { ...f, progress: Math.min(f.progress + increment, 99) } : f));


                            setFiles(files => {
                                const file = files.find(f => f.id === scanFileID);
                                console.log("file", file)
                                const estimatedTimeOCR = file?.estimatedTimeOCR;
                                let increment = 5;
                                if (estimatedTimeOCR) {
                                    increment = 5 / (estimatedTimeOCR / 100);
                                    // round to the nearest integer
                                    increment = Math.round(increment);
                                }

                                return files.map((f, i) => f.id === scanFileID
                                    ? { ...f, progress: Math.min(f.progress + increment, 99) } : f);
                            });
                        // const updatedFilesUploading = filesUploading.map((f, i) => i === fileIndex ?
                        //     { ...f, progress: Math.min(f.progress + 5, 99) } : f);
                        // dispatch(setFilesUploadingInDocumentGeneration({ documentID: generation.id, newFiles: updatedFilesUploading }));

                    } else if (response.data.status === 400) {
                        setFiles(files => files.map((f, i) => f.id === scanFileID ? { ...f, status: 'error' } : f));
                        setFiles(files => files.map((f, i) => f.id === scanFileID ? { ...f, progress: 0 } : f));
                        toast({
                            title: t('Oops, something went wrong. Please cancel and try again.'),
                            status: "error",
                            duration: null,
                            isClosable: true,
                        })
                        clearInterval(interval)
                    }
                }, 5000)
                setIntervals(prevIntervals => ({ ...prevIntervals, [scanFileID]: interval }));
            }
        } catch (error) {
            // const updatedFilesUploading = filesUploading.map((f, i) => i === fileIndex ? { ...f, status: 'error',
            //     progress: 0} : f);
            // dispatch(setFilesUploadingInDocumentGeneration({ documentID: generation.id, newFiles: updatedFilesUploading }));
            setFiles(files => files.map((f, i) => f.id === scanFileID ? { ...f, status: 'error' } : f));
            setFiles(files => files.map((f, i) => f.id === scanFileID ? { ...f, progress: 0 } : f));
            console.log("/pdf/ocr error", error);
        }
    };

    const stopPolling = (scanFileID, fileIndex) => {
        if (intervals[scanFileID]) {
            // remove the file
            setFiles(files => files.filter((f, i) => f.id !== scanFileID));
            clearInterval(intervals[scanFileID]);
            setIntervals(prevIntervals => {
                const newIntervals = { ...prevIntervals };
                delete newIntervals[scanFileID];
                return newIntervals;
            });
        }
    };

    const handleFileDelete = async (generationID, fileID, statusBefore) => {
        const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111";

        console.log("request body for remove_source_multidoc", {
            user_id: userID,
            multidoc_id: generationID,
            file_id: fileID,
            multidoc_session_id: currentGenerationSessionToken !== "" ? currentGenerationSessionToken : null
        })
        try {
            const response = await axios.post(serverIPs.SERVER_IP_MULTIDOC + '/multidoc/remove_source_multidoc', {
                user_id: userID,
                multidoc_id: generationID,
                file_id: fileID,
                multidoc_session_id: currentGenerationSessionToken !== "" ? currentGenerationSessionToken : null
            });
            console.log('Remove Source Multidoc Response:', response);
            if (response.data.status === 200) {
                setFiles(files => files.filter(f => f.id !== fileID));
                return 200;
            } else if (response.data.status === 401) {
                toast({
                    title: t('Must have at least 1 source.'),
                    description: t('Please upload another source before removing this one.'),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
                setFiles(files => files.map((f) => f.id === fileID ? { ...f, status: statusBefore } : f));
            }
        } catch (error) {
            toast({
                title: t('Oops, something went wrong. Please try again.'),
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            console.error('Error removing the source:', error);
        }
    }

    const handleFileUpload = async (fileObj, index) => {
        if (localStorage.getItem("userID") !== null) {
            if (fileObj.type === 'application/pdf') {
                setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'uploading' } : f));

                const formData = new FormData();
                const formUserID = localStorage.getItem("userID");
                const formFilename = fileObj.name;
                const formMultidocID = generation.id;
                const formFile = fileObj.file;
                formData.append('file', formFile);
                formData.append('user_id', formUserID);
                formData.append('filename', formFilename);
                formData.append('multidoc_id', formMultidocID)
                // console.log("FORM DATA BELOW: ")
                // console.log(formData.forEach((value, key) => console.log(key + ' ' + value)));

                try {
                    console.log("/multidoc/get_pdf_upload_url payload", {
                        user_id: formUserID,
                        multidoc_id: formMultidocID,
                        filename: formFilename
                    })
                    console.log(serverIPs.SERVER_IP_MULTIDOC);
                    const firstResponse = await axios.post(serverIPs.SERVER_IP_MULTIDOC + '/multidoc/get_pdf_upload_url', {
                        user_id: formUserID,
                        multidoc_id: formMultidocID,
                        filename: formFilename
                    });
                    console.log("firstResponse", firstResponse);
                    setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 10 } : f));



                    const secondResponse = await axios.put(firstResponse.data.message.presigned_url, formFile, {
                        headers: {
                            'Content-Type': 'application/pdf'
                        }
                    });

                    if (secondResponse.status === 200) {
                        console.log('PDF uploaded successfully AWS!', secondResponse);
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 24 } : f));
                    } else {
                        console.error('Failed to upload PDF AWS:', secondResponse.statusText);
                    }



                    console.log("complete_pdf_upload payload", {
                        user_id: formUserID,
                        multidoc_id: formMultidocID,
                        file_id: firstResponse.data.message.file_id
                    });
                    const response = await axios.post(serverIPs.SERVER_IP_MULTIDOC + '/multidoc/complete_pdf_upload', {
                        user_id: formUserID,
                        multidoc_id: formMultidocID,
                        file_id: firstResponse.data.message.file_id
                    }, {
                        onUploadProgress: (progressEvent) => {
                            console.log("Progress Event", progressEvent);
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setFiles(files => files.map((f, i) => i === index ? { ...f, progress: percentCompleted } : f));
                        }
                    });
                    console.log('Uploaded MULTIDOC Response:', response);
                    if (response.data.message.message ===
                        "Scanned PDF detected, do you want to run OCR on this PDF? Call the proper follow up route if yes.") {
                        setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'ocring' } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, id: response.data.message.file_id,
                            estimatedTimeOCR: response.data.message.time_estimate_seconds } : f));
                        // const estimatedTimeOCR = response.data.message.time_estimate_seconds;

                        // track this estimated time for this specific file, so that we know how many seconds should go by before increasing the progress bar by 5%
                        // setFiles(files => files.map((f, i) => i === index ? { ...f, estimatedTimeOCR: estimatedTimeOCR } : f));
                        startDocumentProcessing(response.data.message.file_id, index)
                        return;
                    }
                    if (response.data.status === 400) {
                        setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'error' } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                        toast({
                            title: t('PDF is too large.'),
                            description: t('We support up to 512 MB or 147,000 words'),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                    else if (response.data.message === "Can upload a maximum of 8 sources in free tier. Please upgrade.") {
                        setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'error' } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                        toast({
                            title: t('Maximum of 8 sources allowed.'),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                    else if (response.data.status === 401) {
                        setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'error' } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                        toast({
                            title: t('Oops, this PDF failed.'),
                            description: t('Please try again or upload a different PDF.'),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                    else if (response.data.status === 402 && response.data.message === "User exceeded free tier upload limit. Please upgrade.") {
                        console.log("pricing_modal_shown")
                        // remove the file
                        setFiles(files => files.filter((f, i) => i !== index));
                        toast({
                            title: t('(Free Plan) PDF Upload Limit Exceeded!'),
                            status: "info",
                            duration: 9000,
                            isClosable: true,
                            position: "top",
                        });
                        dispatch(setIsPricingModalOpen(true));
                        localStorage.setItem("isUploadExceeded", "true");
                    } else if (response.data.status === 403) {
                        setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'error' } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                        toast({
                            title: t('Free Plan supports <= 20 pages for scanned PDFs'),
                            description: t('Visit honeybear.ai/pricing to view Pro Plan pricing'),
                            status: "error",
                            duration: null,
                            isClosable: true,
                        })
                    }
                    else {
                        // addActiveSourcesMultidoc(sessionTokenGeneration, localStorage.getItem("userID"),
                        //         [response.data.message.file_id]);
                        setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'completed' } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                        setFiles(files => files.map((f, i) => i === index ? { ...f, id: response.data.message.file_id } : f));
                    }
                } catch (error) {
                    setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'error' } : f));
                    setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                    toast({
                        title: t('Oops, this PDF failed.'),
                        description: t('Please try again or upload a different PDF.'),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                    console.error('Error uploading the document:', error);
                }
            } else {
                setFiles(files => files.map((f, i) => i === index ? { ...f, status: 'error' } : f));
                setFiles(files => files.map((f, i) => i === index ? { ...f, progress: 0 } : f));
                toast({
                    title: t('Upload failed.'),
                    description: t('We only support PDF documents.'),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    };


    return (
        <>
            <Modal isOpen={isOpenGeneratePromptScreen2} onClose={closeModal} isCentered size="xl" closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="42rem" maxW="50rem" overflowY="auto" position="relative">
                    {canCloseModal() ?
                        <Box
                            position="absolute"
                            top="10px"
                            right="10px"
                            zIndex={999}
                        >
                            <CloseButton
                                size="md"
                                onClick={() => {
                                    closeModal();
                                }}
                                bg="transparent"
                                borderRadius="50%"
                                _hover={{
                                    bg: "gray.200",
                                    borderRadius: "50%",
                                }}
                            />
                        </Box> : null}
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="flex-start" width={'100%'} spacing={5}>
                            <Text fontSize="xl" fontWeight="bold">{t('Upload Sources')}</Text>
                            <VStack align="flex-start" width={'100%'} spacing={2}>
                                <Text fontSize={15} fontWeight="normal">Get responses based on your uploaded sources, with in-text citations.</Text>
                                <Text fontSize={15} fontWeight="normal">(Examples: marketing plans, class readings, research notes, meeting transcripts, sales documents, etc.)</Text>
                            </VStack>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4} mt={1}>
                            <FormControl id="prompt" isRequired>
                                <VStack spacing={6} height={'8.3rem'}>
                                    <MotionBox
                                        {...getRootProps()}
                                        border="2px"
                                        borderColor="orange.400"
                                        borderRadius="md"
                                        borderStyle="dashed"
                                        textAlign="center"
                                        p="2"
                                        cursor="pointer"
                                        _hover={{ bg: 'gray.100' }}
                                        animate={isDragActive ? { scale: 1.05 } : { scale: 1 }}
                                        transition={{ duration: 0.2 }}
                                        width="100%"
                                        height="100%"
                                        onClick={() => {
                                            if (getDocumentsInStorage().length === 1 && !auth.isLoggedIn) {
                                                toast({
                                                    title: t("You've reached your document limit in guest mode!"),
                                                    description: t('Please sign up to continue uploading documents.'),
                                                    status: "info",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "top",
                                                });
                                                onOpenSignUpScreen1();
                                            }
                                            else if (!JSON.parse(localStorage.getItem("isProPlan")) && localStorage.getItem("isUploadExceeded") === "true") {
                                                toast({
                                                    title: t('(Free Plan) PDF Upload Limit Exceeded!'),
                                                    status: "info",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "top",
                                                });
                                                dispatch(setIsPricingModalOpen(true));
                                            }
                                            else {
                                                fileInput.current.click();
                                            }
                                        }}
                                    >
                                        <input {...getInputProps({ accept: 'application/pdf' })} ref={fileInput}/>
                                        <Box as="span" color="gray.400" fontSize="3xl">
                                            ⇧
                                        </Box>
                                        <Text fontWeight="bold" color="gray.400" fontSize={"sm"}>
                                            {t('Drag & Drop')}
                                        </Text>
                                        <Text color="gray.500" fontSize={"sm"}>{t('or click to browse')}</Text>
                                    </MotionBox>

                                    {/*<SourceLimitBar currentSources={generation.files.length} maxSources={10} />*/}

                                    {/*<Text fontSize={'sm'} color={'gray.500'}>or</Text>*/}

                                    {/*<URLInput*/}
                                    {/*    urlInput={urlInput}*/}
                                    {/*    setUrlInput={setUrlInput}*/}
                                    {/*    handleSubmit={handleSubmit}*/}
                                    {/*    currentPlaceholder={currentPlaceholder}*/}
                                    {/*    fade={fade}*/}
                                    {/*/>*/}
                                </VStack>
                            </FormControl>

                            <VStack
                                align="flex-start"
                                width={'100%'}
                                spacing={0}
                                mt={'0rem'}
                                height="270px"
                                overflowY="auto"
                                sx={{
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                        borderRadius: '8px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                    },
                                }}
                            >
                                {files.map((file, i) => {
                                    return(
                                        <HStack
                                            key={i} // Unique key for each item
                                            w="100%"
                                            alignItems="center"
                                            mt={1}
                                            borderWidth="1px"
                                            borderRadius="md"
                                            borderColor="gray.200"
                                            px={2}
                                            py={2}
                                            boxShadow="sm"
                                        >
                                            <Icon as={ImFilePdf} mx={2}></Icon>
                                            <VStack align={'flex-start'} width={'85%'} >
                                                <Text fontWeight="bold" fontSize={"sm"} noOfLines={2} // Set to 2 lines
                                                      wordBreak="break-word">{file.name}</Text>
                                                <HStack justify={'space-between'} align={'space-between'} width={'100%'}>
                                                    <Text color="gray.500" fontSize={"xs"}>{(file.size / 1024 / 1024).toFixed(2)} MB</Text>
                                                    {(file.status === 'uploading' || file.status === 'ocring') && (
                                                        file.status === "ocring" ?
                                                            <Text color="black" fontSize={"xs"}>{t("scanning, this may take a bit longer...")}{Math.max(0, file.progress - 1)}%</Text> :
                                                            <Text color="black" fontSize={"xs"}>{Math.max(0, file.progress - 1)}%</Text>
                                                    )}
                                                </HStack>
                                                {(file.status === 'uploading' || file.status === 'ocring') && (
                                                    <Progress
                                                        colorScheme="pink"
                                                        size="xs"
                                                        width="100%"
                                                        my={1}
                                                        value={Math.max(0, file.progress - 1)}
                                                    />
                                                )}
                                            </VStack>
                                            <Spacer />
                                            {file.status === 'error' ?
                                                <Text color="red" fontSize={"xs"}>{t('Error')}</Text>
                                                :
                                                file.status !== 'uploading' &&
                                                <Button colorScheme="gray" size="xs" variant="ghost"
                                                        isLoading={file.status === 'removing'}
                                                        onClick={() => {
                                                            console.log("Removing file", file.id, i)
                                                            if (file.status === 'ocring') {
                                                                const statusBefore = file.status;
                                                                setFiles(files => files.map((f) => f.id === file.id ? { ...f, status: 'removing' } : f));
                                                                handleFileDelete(generation.id, file.id, statusBefore).then(r => {
                                                                    console.log("r response", r)
                                                                    if (r === 200) {
                                                                        stopPolling(file.id, i);
                                                                    }
                                                                });
                                                            } else {
                                                                const statusBefore = file.status;
                                                                setFiles(files => files.map((f) => f.id === file.id ? { ...f, status: 'removing' } : f));
                                                                handleFileDelete(generation.id, file.id, statusBefore).then(r => {
                                                                    console.log("r response", r)
                                                                });
                                                            }
                                                        }}>
                                                    <Icon as={CloseIcon} />
                                                </Button>
                                            }
                                        </HStack>
                                        )
                                })}
                            </VStack>
                            {files.length > 0 ?

                            <Flex width="100%" justify="center" mt={2}>
                                <Button
                                    loadingText="Uploading..."
                                    size="lg"
                                    isLoading={isLoading}
                                    isDisabled={files.some(file => file.status === 'uploading' || file.status === 'ocring')}
                                    onClick={uploadSources}
                                    bgGradient={'linear(to-r, red.400,pink.400)'}
                                    color={'white'}
                                    width={'55%'}
                                    _hover={{
                                        boxShadow: 'xl',
                                    }}
                                >
                                    {t('Upload')}
                                </Button>
                            </Flex>
                        : null}

                        </Stack>
                    </ModalBody>
                    {/* {files.length > 0 ?
                        <ModalFooter>
                            <Flex width="100%" justify="center">
                                <Button
                                    loadingText="Uploading..."
                                    size="lg"
                                    isLoading={isLoading}
                                    isDisabled={files.some(file => file.status === 'uploading' || file.status === 'ocring')}
                                    onClick={uploadSources}
                                    bgGradient={'linear(to-r, red.400,pink.400)'}
                                    color={'white'}
                                    width={'55%'}
                                    _hover={{
                                        boxShadow: 'xl',
                                    }}
                                >
                                    {t('Upload')}
                                </Button>
                            </Flex>
                        </ModalFooter> : null} */}
                </ModalContent>
            </Modal>

            <SignUpScreen1 isOpenSignUpScreen1={isOpenSignUpScreen1} onCloseSignUpScreen1={onCloseSignUpScreen1} onOpenSignInScreen1={onOpenSignInScreen1}/>
            <SignInScreen1 isOpenSignInScreen1={isOpenSignInScreen1} onCloseSignInScreen1={onCloseSignInScreen1} onOpenSignUpScreen1={onOpenSignUpScreen1}/>
            <ScanModal1 isOpenScanModal1={isOpenScanModal1} onCloseScanModal1={onCloseScanModal1} onOpenScanModal1={onOpenScanModal1}/>
            <ScanModalIntro1 isOpenScanModalIntro1={isOpenScanModalIntro1} onCloseScanModalIntro1={onCloseScanModalIntro1}
                             onOpenScanModalIntro1={onOpenScanModalIntro1}/>
        </>
    );
};

export default GeneratePromptScreen2;








