import React, {useEffect, useState} from 'react';
import {Flex, Input, useBoolean, useBreakpointValue, Tooltip, useToast} from '@chakra-ui/react';
import {useAuth} from "../../redux/AuthProvider";
import {useDispatch, useSelector} from "react-redux";
import {renameGeneration} from "../../utils/home-page-helpers";
import {changeNameOfDocumentGeneration} from "../../redux/reducers";
import {useTranslation} from "react-i18next";
import {renameMultidoc} from "../../utils/multi-doc-helpers";
import {useServerIP} from "../../redux/ServerIPContext";

const EditTitleGeneration = ({generationTitle, generation}) => {
    const auth = useAuth();
    const toast = useToast();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const generations = useSelector((state) => state.documents.savedGenerations);

    const [isHovering, setIsHovering] = useBoolean();
    const [isFocused, setIsFocused] = useBoolean();
    const [title, setTitle] = useState(generationTitle);
    const inputRef = React.useRef(null);
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    // This function will be called every time the input changes
    const handleChange = async (event) => {
        setTitle(event.target.value);
    };

    const handleRename = async (untitled) => {
        let theTitle = untitled === "Untitled Workspace" ? "Untitled Workspace" : title;

        const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111";
        await renameMultidoc(theTitle, userID, generation.id, serverIPs.SERVER_IP_MULTIDOC).then((response) => {
            if (response === null) {
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t("401 error. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                toast({
                    title: t("Successfully renamed."),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            }
        });
    }

    const handleBlur = async () => {
        if (title === "") {
            setTitle("Untitled Workspace");
            await handleRename("Untitled Workspace");
        } else {
            await handleRename("");
        }
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default Enter key behavior
            inputRef.current?.blur();
        }
    };

    return (
        !isMobile ?
            <Flex flexGrow={1} justifyContent="center" width={!isMobile && auth.isLoggedIn ? "33%" : "55%"}>
                {auth.isLoggedIn && (generations !== null && generations.length > 0) ?
                    <Tooltip label={t("Rename")} isOpen={isHovering && !isFocused} placement="bottom" hasArrow={true}>
                        <Input
                            ref={inputRef}
                            value={title}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            onMouseEnter={setIsHovering.on}
                            onMouseLeave={setIsHovering.off}
                            onFocus={setIsFocused.on}
                            onBlur={() => {
                                setIsFocused.off();
                                handleBlur().then(r => {}); // Call handleBlur here
                            }}
                            borderColor={isFocused ? 'darkblue' : isHovering ? 'lightblue' : 'gray.100'}
                            _hover={{ borderColor: 'lightblue' }}
                            _focus={{ borderColor: 'darkblue' }}
                            fontWeight={'bold'}
                            textAlign={'center'}
                        />
                    </Tooltip>
                    : null}
            </Flex>
            : null
    );
};

export default EditTitleGeneration
