import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast
} from "@chakra-ui/react";
import GoogleButton from "../../buttons/GoogleButton";
import {handleSignUp} from "../../../utils/auth-helpers";
import {deleteDocument, deleteGeneration} from "../../../utils/home-page-helpers";
import {fetchDocuments, fetchGenerations} from "../../../utils/redux-helpers";
import {setDocuments, setDocumentsGeneration} from "../../../redux/reducers";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {deleteMultidoc, fetchMultidocs} from "../../../utils/multi-doc-helpers";
import {useServerIP} from "../../../redux/ServerIPContext";

const DeleteScreenGeneration = ({ isOpenDeleteGeneration, onCloseDeleteGeneration, userID, generationID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const deleteAction = async () => {
        setIsLoading(true)
        await deleteMultidoc(userID, generationID, serverIPs.SERVER_IP_MULTIDOC).then((response) => {
            setIsLoading(false)
            if (response === null) {
                toast({
                    title: t('Oops, something went wrong. Please try again.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t('401 error. Please try again.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                fetchMultidocs(userID, serverIPs.SERVER_IP_MULTIDOC).then(r => {
                    dispatch(setDocumentsGeneration(r.multidocs));
                })
                toast({
                    title: t('Successfully deleted.'),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                onCloseDeleteGeneration();
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpenDeleteGeneration} onClose={onCloseDeleteGeneration} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="18rem" w="26rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseDeleteGeneration}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="xl" fontWeight="bold">{t('Are You Sure?')}</Text>
                            <Text fontSize="sm" fontWeight="light" textAlign={"center"}>{t('This workspace and all its data and documents will be deleted. ' +
                                'You can always create a new workspace and start from scratch.')}</Text>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4}>
                            <Stack spacing={10} pt={4}>
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    isLoading={isLoading}
                                    bg={'red.400'}
                                    color={'white'}
                                    onClick={deleteAction}
                                    _hover={{
                                        bg: 'red.500',
                                    }}>
                                    {t('Delete Workspace')}
                                </Button>
                            </Stack>
                            {/*<Stack pt={6}>*/}
                            {/*    <Text align={'center'}>*/}
                            {/*        Already a user? <Link color={'blue.400'} onClick={()=>{*/}
                            {/*        onCloseSignUpScreen1();*/}
                            {/*        onOpenSignInScreen1();*/}
                            {/*    }}>Sign In</Link>*/}
                            {/*    </Text>*/}
                            {/*</Stack>*/}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/*<SignUpScreen2*/}
            {/*    isOpen={isOpenSignUpScreen2}*/}
            {/*    onClose={() => setIsOpenSignUpScreen2(false)}*/}
            {/*    email={email}*/}
            {/*/>*/}
        </>
    );
};

export default DeleteScreenGeneration;








