import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Spinner, useBreakpointValue, useDisclosure, useToast} from "@chakra-ui/react";
import PDFPage from "./PDFPage";
import {io} from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {SERVER_IP, SERVER_IP_SOCKET} from "../../utils/constants";
import {getSessionToken, getSessionTokenAudiobook} from "../../utils/pdf-page-helpers";
import {useAuth} from "../../redux/AuthProvider";
import {
    setAudiobooks,
    setHighlightsInDocument,
    setMessagesInAudiobook,
    setMessagesInDocument,
    setMessagesInDocumentGeneration
} from "../../redux/reducers";
import {MySpinnerLarge} from "../../components/Reuseable/MySpinner";
import ScanModal1 from "../../components/Modals/Scans/ScanModal1";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import {useServerIP} from "../../redux/ServerIPContext";
import PDFPageAudiobook from "./PDFPageAudiobook";
import {createAudiobook, getOutline} from "../../utils/audiobook-helpers";
import {getAudioURLsFromLocalStorage} from "../../utils/local-storage-helpers";
import {AnimatePresence, motion} from "framer-motion";
import {fetchAudiobooks} from "../../utils/redux-helpers";
import { v4 as uuidv4 } from 'uuid';

// first time top down run thru done
export default function WrapperComponentAudiobook ()  {
    const auth = useAuth();
    const toast = useToast();
    const [socketAudiobook, setSocketAudiobook] = useState(null);
    const [audiobookOutline, setAudiobookOutline] = useState(null);
    const { id } = useParams();
    const [sessionTokenAudiobook, setSessionTokenAudiobook] = useState("")
    const [currentAudiobook, setCurrentAudiobook] = useState(null);
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const audiobooks = useSelector(state => state.documents.savedAudiobooks)
    // const documents = auth.isLoggedIn ? savedDocuments : auth.playgroundDocuments;

    const [forceRender, setForceRender] = useState(0);
    const [socketIDAudiobook, setSocketIDAudiobook] = useState("");
    const { isTypingAudiobook, setIsTypingAudiobook } = useAuth();
    const {isThinkingAudiobook, setIsThinkingAudiobook} = useAuth();
    const dispatch = useDispatch();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base';

    const [suggestedQuestionsStream, setSuggestedQuestionsStream] = useState("");
    const [suggestedQuestions, setSuggestedQuestions] = useState([]);

    useEffect( () => {
        console.log("In UseEffect WrapperComponent with id:", id);
        const randomID = uuidv4();

        let userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"

        if (userID === "11111111-1111-1111-1111-111111111111") {
            // append the randomID to the userID
            userID = userID + randomID;
        }
        console.log("USERID guest with random ID", userID)

        const newSocketAudiobook = io(serverIPs.SERVER_IP_SOCKET, {
            query: { user_id: userID }
        });
        setSocketAudiobook(newSocketAudiobook);
        console.log("Socket:", socketAudiobook)
        newSocketAudiobook.on('connected', function (data) {
            getOutline(id, serverIPs.SERVER_IP, toast).then(r => {
                const isOutlineEmpty = Object.keys(r.outline).length === 0;
                if (r && !isOutlineEmpty) {
                    setAudiobookOutline(r.outline);
                }
                else {
                    createAudiobook(id, localStorage.getItem("userID"), serverIPs.SERVER_IP, "user_" + userID, toast).then(r => {
                        console.log("createAudiobook response", r);
                    });
                }
            });
            console.log("Connected:", data);
            setSocketIDAudiobook("user_" + userID);
        });

        newSocketAudiobook.on('audiobook_generate_starting_outline_audio_chunks', function (data) {
            console.log("audiobook_generate_starting_outline_audio_chunks DATA", data);
            if (data.message === "Outline has been created.") {
                setAudiobookOutline(data.outline);
            }
        });
        return () => {
            console.log("Socket disconnecting", newSocketAudiobook)
            // newSocketAudiobook.off('receive_suggested_question_message');
            newSocketAudiobook.disconnect();
        }
    }, [id])

    useEffect(() => {
        console.log("In UseEffect WrapperComponent Audiobook with socket:", socketAudiobook);
        console.log("AUDIOBOOKS 1", audiobooks);
        if (audiobooks.length > 0) {
            console.log("Audiobooks length > 0")
            setCurrentAudiobook(audiobooks.find((document) => document.id === id));
            setForceRender(prevForceRender => prevForceRender + 1);
        }
        console.log("AUDIOBOOKS 2", audiobooks);
    }, [audiobooks.length, id]);

    // useEffect(() => {
    //     console.log("In UseEffect WrapperComponent with socket:", socketAudiobook);
    //     console.log("audiobooksReal", audiobooksReal);
    //     if (audiobooks.length > 0) {
    //         console.log("Audiobooks length > 0")
    //         setCurrentAudiobook(audiobooks.find((audiobook) => audiobook.id === id));
    //         setForceRender(prevForceRender => prevForceRender + 1);
    //     }
    // }, [audiobooks.length, id]);

    // useEffect(() => {
    //     console.log("In UseEffect WrapperComponent with currentAudiobook:", currentAudiobook);
    //     console.log("In UseEffect WrapperComponent with socketIDAudiobook:", socketIDAudiobook)
    //     console.log("In UseEffect WrapperComponent with sessionTokenAudiobook:", sessionTokenAudiobook)
    //
    //     // if (currentAudiobook && sessionTokenAudiobook === "" && socketIDAudiobook) {
    //     //     console.log("CurrentAudiobook is not null")
    //     //     const fetchSessionTokenAudiobook = async () => {
    //     //         const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
    //     //         console.log("USERID", userID)
    //     //         const response = await getSessionTokenAudiobook(id, userID, socketIDAudiobook, serverIPs.SERVER_IP);
    //     //         if (response) {
    //     //             console.log("RESPONSE FROM START SESSION", response);
    //     //             // if (response.doc_metadata.highlights) {
    //     //             //     dispatch(setHighlightsInDocument({documentID: currentDocument.id,
    //     //             //         newHighlights: response.doc_metadata.highlights.slice().reverse()}));
    //     //             // }
    //     //             if (response.doc_metadata.messages) {
    //     //                 dispatch(setMessagesInAudiobook({documentID: currentAudiobook.id, newMessages: response.doc_metadata.messages}));
    //     //                 // dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
    //     //             }
    //     //             dispatch(setMessagesInAudiobook({documentID: currentAudiobook.id, newMessages: []}));
    //     //             setSessionTokenAudiobook(response.sessionToken);
    //     //         }
    //     //     };
    //     //     fetchSessionTokenAudiobook().then(r => {});
    //     // } else if (socketIDAudiobook === "" && currentAudiobook && sessionTokenAudiobook === "") {
    //     //     console.log("CurrentAudiobook is not null")
    //     //     const fetchSessionTokenAudiobook = async () => {
    //     //         const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
    //     //         console.log("USERID", userID)
    //     //         const response = await getSessionTokenAudiobook(id, userID, null, serverIPs.SERVER_IP);
    //     //         if (response) {
    //     //             console.log("RESPONSE FROM START SESSION", response);
    //     //             // if (response.doc_metadata.highlights) {
    //     //             //     dispatch(setHighlightsInDocument({documentID: currentDocument.id,
    //     //             //         newHighlights: response.doc_metadata.highlights.slice().reverse()}));
    //     //             // }
    //     //             if (response.doc_metadata.messages) {
    //     //                 dispatch(setMessagesInAudiobook({documentID: currentAudiobook.id, newMessages: response.doc_metadata.messages}));
    //     //                 // dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
    //     //             }
    //     //             dispatch(setMessagesInAudiobook({documentID: currentAudiobook.id, newMessages: []}));
    //     //             setSessionTokenAudiobook(response.sessionToken);
    //     //         }
    //     //     };
    //     //     fetchSessionTokenAudiobook().then(r => {});
    //     // }
    // }, [currentAudiobook, socketIDAudiobook]);

    useEffect(() => {
        console.log("In UseEffect WrapperComponent with currentDocument:", currentAudiobook);
        console.log("In UseEffect WrapperComponent with socketID:", socketIDAudiobook)
        console.log("In UseEffect WrapperComponent with sessionToken:", sessionTokenAudiobook)

        getOutline(id, serverIPs.SERVER_IP, toast).then(r => {
            const isOutlineEmpty = Object.keys(r.outline).length === 0;
            if (r && !isOutlineEmpty) {
                setAudiobookOutline(r.outline);
            }
        });
    }, [currentAudiobook, socketIDAudiobook]);

    const [loadingStep, setLoadingStep] = useState(0);
    const loadingMessages = [
        "We're preparing your workspace...",
        "We're loading up your documents...",
        "Putting some final touches so you're ready to go!"
    ];

    useEffect(() => {
        if (audiobookOutline === null) {
            const timer = setInterval(() => {
                setLoadingStep((prev) => (prev + 1) % loadingMessages.length);
            }, 3000); // Change message every 3 seconds
            return () => clearInterval(timer);
        }
    }, [audiobookOutline]);

    const LoadingScreen = () => (
        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100%',
            paddingTop: "0rem",
            background: 'linear-gradient(135deg, #f6f8ff 0%, #f1f5ff 100%)'
        }}>
            <motion.div
                animate={{
                    scale: [1, 1.2, 1],
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                }}
            >
                <div style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                    border: '3px solid transparent',
                    borderTopColor: '#3182ce',
                    borderBottomColor: '#3182ce',
                    animation: 'spin 1.5s linear infinite'
                }}>
                    <style>
                        {`
                            @keyframes spin {
                                0% { transform: rotate(0deg); }
                                100% { transform: rotate(360deg); }
                            }
                        `}
                    </style>
                </div>
            </motion.div>

            <AnimatePresence mode='wait'>
                <motion.div
                    key={loadingStep}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    style={{
                        marginTop: '4rem',
                        marginBottom: '1rem',
                        fontSize: '1.25rem',
                        fontWeight: '500',
                        color: '#2d3748',
                        textAlign: 'center',
                        maxWidth: '80%'
                    }}
                >
                    {loadingMessages[loadingStep]}
                </motion.div>
            </AnimatePresence>

            <motion.div
                animate={{
                    opacity: [0.5, 1, 0.5],
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                }}
                style={{
                    marginTop: '1rem',
                    display: 'flex',
                    gap: '0.5rem'
                }}
            >
                {[...Array(3)].map((_, i) => (
                    <div
                        key={i}
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: i === loadingStep ? '#3182ce' : '#CBD5E0'
                        }}
                    />
                ))}
            </motion.div>
        </div>
    );

    if (!currentAudiobook) {
        return <LoadingScreen/>
    }
    return (
        audiobookOutline === null ?
            <LoadingScreen/>
            :
        <div>
            <PDFPageAudiobook document={audiobooks.find((document) => document.id === id)}
                              isMobile={isMobile}
                              key={forceRender}
                              audiobookOutline={audiobookOutline}
                              documents={audiobooks}
                              id={id}
                              fileID={id}
                              userID={localStorage.getItem("userID")}
                              isLoggedIn={auth.isLoggedIn}
                              sessionToken={sessionTokenAudiobook}
                              socketID={socketIDAudiobook}
                              socket={socketAudiobook}
                              isTyping={isTypingAudiobook} setIsTyping={setIsTypingAudiobook}
                              isThinking={isThinkingAudiobook} setIsThinking={setIsThinkingAudiobook}
                              toast={toast} suggestedQuestions={suggestedQuestions}
                              serverIP={serverIPs.SERVER_IP}/>
            <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                           onClosePricingScreen={onClosePricingScreen}
                           onOpenPricingScreen={onOpenPricingScreen}/>
        </div>
    )
}







