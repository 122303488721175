import axios from "axios";

export const createAudiobook = async (file_id, user_id, serverIP, socketio_stream_room, toast) => {
    console.log("request body for createAudiobook", {
        "file_id": file_id.toString(),
        "user_id": user_id.toString(),
        "socketio_stream_room": socketio_stream_room,
        "serverIP": serverIP,
    })

    try {
        const response = await axios.post(serverIP + '/audiobooks/create_audiobook', {
            "file_id": file_id.toString(),
            "user_id": user_id.toString(),
            "socketio_stream_room": socketio_stream_room,
        });
        console.log("create_audiobook", response)

        if (response.data.status === 400) {
            toast({
                title: "Oops! Something went wrong. Please try again.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "outline": response.data.message.outline,
                "message": response.data.message.message,
                "job_id": response.data.message.job_id,
            }
        }
    } catch (error) {
        console.log("/audiobook/create_audiobook error", error);
        return null;
    }
}

export const getOutline = async (file_id, serverIP, toast) => {
    console.log("request body for getOutline", {
        "file_id": file_id.toString(),
        "serverIP": serverIP,
    })

    try {
        const response = await axios.post(serverIP + '/audiobooks/get_outline', {
            "file_id": file_id.toString(),
        });
        console.log("get_outline", response)

        if (response.data.status === 400) {
            toast({
                title: "Oops! Something went wrong. Please try again.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "outline": response.data.message.outline,
            }
        }
    } catch (error) {
        console.log("/audiobook/get_outline error", error);
        return null;
    }
}

export const getPage = async (file_id, user_id, page_number, socketio_stream_room, serverIP, toast) => {
    console.log("request body for getPage", {
        "file_id": file_id.toString(),
        "user_id": user_id.toString(),
        "page_number": page_number.toString(),
        "socketio_stream_room": socketio_stream_room,
        "serverIP": serverIP,
    })

    try {
        const response = await axios.post(serverIP + '/audiobooks/get_page', {
            "file_id": file_id.toString,
            "user_id": user_id.toString(),
            "page_number": page_number.toString(),
            "socketio_stream_room": null,
        });
        console.log("GET_PAGE RESPONSE", response)

        if (response.data.status === 400) {
            toast({
                title: "Oops! Something went wrong. Please try again.",
                status: "error",
                duration: 5500,
                isClosable: true,
                position: "top"
            })
        }
        else if (response.data.status === 200) {
            return {
                "status" : 200,
                "outline": response.data.message.outline,
            }
        }
    } catch (error) {
        console.log("/audiobook/get_page error", error);
        return null;
    }
}
