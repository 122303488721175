import React, { useState } from "react";
import {
    Box,
    Flex,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useColorModeValue,
    IconButton, Text,
    keyframes, useBreakpointValue
} from '@chakra-ui/react';
import { Chat } from './Chat';
import { Highlights } from './Highlights';
import { useDispatch, useSelector } from "react-redux";
import {
    addMessageInDocument,
    setAskContent, setAskImageContent, setCurrentChatInput,
    setFollowUpContent, setWhichTab, toggleIsAskImageVisibleFalse,
    toggleIsAskVisibleFalse,
    toggleIsChatVisible, toggleIsFollowUpVisibleFalse
} from "../../redux/reducers";
import { v4 as uuidv4 } from 'uuid';
import {useAuth} from "../../redux/AuthProvider";
import {AiOutlineZoomOut} from "react-icons/ai";
import {FiChevronUp, FiMinimize2} from "react-icons/fi";
import lightTheme from "../../utils/styles";
import {CustomIconButton, CustomIconButtonWhite} from "../buttons/CustomIconButton";
import {Generation} from "./Generation";
import {useTranslation} from "react-i18next";
import {modelMap} from "../../utils/constants";
import {Transcript} from "./Transcript";

// handleChatResponseNoSelection = async (type, pageNumber, sessionToken, socketID, question)
export function SidebarAudiobook({ stopClaudeResponse, highlights, document, sessionToken, audiobookOutline, width, handleChatResponseNoSelection, socketID, socket,
                                     handleChatResponseSelection, handleChatFollowUp, deleteHighlight, updateHighlightInDoc, suggestedQuestions,
                                     fileID, userID}) {
    const dispatch = useDispatch();
    const documents = useSelector(state => state.documents.savedDocuments);
    const isAskVisible = useSelector(state => state.settings.isAskVisible);
    const isAskImageVisible = useSelector(state => state.settings.isAskImageVisible);
    const isFollowUpVisible = useSelector(state => state.settings.isFollowUpVisible);
    const askContent = useSelector(state => state.settings.askContent);
    const askImageContent = useSelector(state => state.settings.askImageContent);
    const followUpContent = useSelector(state => state.settings.followUpContent);
    const whichTab = useSelector(state => state.settings.whichTab)
    const currentChatInput = useSelector(state => state.settings.currentChatInput);

    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base';
    const {t} = useTranslation();

    // type, message_responding_to, follow_up_question, sessionToken, socketID
    const handleNewMessage = (chatInput, model) => {
        if (isAskVisible) {
            handleChatResponseSelection('ask_selection', askContent, sessionToken, socketID, chatInput, modelMap[model]);
            dispatch(toggleIsAskVisibleFalse());
            dispatch(setAskContent(null));
        }
        else if (isAskImageVisible) {
            handleChatResponseSelection('ask_image', askImageContent, sessionToken, socketID, chatInput, modelMap[model]);
            dispatch(toggleIsAskImageVisibleFalse());
            dispatch(setAskImageContent(null));
        }
        else if (isFollowUpVisible) {
            handleChatFollowUp("follow_up", followUpContent.response, chatInput, sessionToken, socketID, modelMap[model]);
            dispatch(toggleIsFollowUpVisibleFalse());
            dispatch(setFollowUpContent(null));
        } else {
            handleChatResponseNoSelection('general_question', null, sessionToken, socketID, chatInput, modelMap[model]);
        }
        dispatch(setCurrentChatInput(''));
    };

    const renderComponent = () => {
        const tabComponents = {
            // 1: <Chat
            //     theDocument={document}
            //     handleNewMessage={handleNewMessage}
            //     handleChatResponseNoSelection={handleChatResponseNoSelection}
            //     sessionToken={sessionToken}
            //     socketID={socketID}
            //     stopClaudeResponse={stopClaudeResponse}
            //     suggestedQuestions={suggestedQuestions}
            // />,
            1: <Transcript
                theDocument={document}
                handleNewMessage={handleNewMessage}
                handleChatResponseNoSelection={handleChatResponseNoSelection}
                sessionToken={sessionToken}
                socketID={socketID}
                socket={socket}
                stopClaudeResponse={stopClaudeResponse}
                suggestedQuestions={suggestedQuestions}
                audiobookOutline={audiobookOutline}
                fileID={fileID}
                userID={userID}
            />,
            // 3: <Highlights
            //     highlights={highlights}
            //     deleteHighlight={deleteHighlight}
            //     updateHighlightInDoc={updateHighlightInDoc}
            // />,
        };

        return tabComponents[whichTab];
    };

    return (
        <Box width={`${width}%`} height="100%" display="flex" flexDirection="column">
            <Flex height="3rem" backgroundColor={lightTheme.colors.white} alignItems="center"
                  justifyContent="space-between" px={'1rem'} borderBottomColor={lightTheme.colors.lightGray}
                  borderBottomWidth={"0.5px"}>
                <Flex alignItems={"center"}>
                    {/*<Button*/}
                    {/*    borderRadius={0}*/}
                    {/*    backgroundColor={lightTheme.colors.white}*/}
                    {/*    onClick={() => dispatch(setWhichTab(1))}*/}
                    {/*    borderBottom={whichTab === 1 ? "2px solid blue" : "none"}*/}
                    {/*    _hover={{ backgroundColor: lightTheme.colors.white }}*/}
                    {/*>*/}
                    {/*    {t("Chat")}*/}
                    {/*</Button>*/}
                    <Button
                        borderRadius={0}
                        backgroundColor={lightTheme.colors.white}
                        onClick={() => dispatch(setWhichTab(1))}
                        borderBottom={whichTab === 1 ? "2px solid blue" : "none"}
                        _hover={{ backgroundColor: lightTheme.colors.white }}
                    >
                        Transcript
                    </Button>
                    {/*<Button*/}
                    {/*    borderRadius={0}*/}
                    {/*    backgroundColor={lightTheme.colors.white}*/}
                    {/*    onClick={() => dispatch(setWhichTab(3))}*/}
                    {/*    borderBottom={whichTab === 3 ? "2px solid blue" : "none"}*/}
                    {/*    _hover={{ backgroundColor: lightTheme.colors.white }}*/}
                    {/*>*/}
                    {/*    {t("Notes")}*/}
                    {/*</Button>*/}
                </Flex>

                {!isMobile ?
                    <CustomIconButtonWhite
                        icon={FiMinimize2}
                        size={19}
                        ariaLabel="Minimize Chat"
                        onClick={() => {
                            dispatch(toggleIsChatVisible());
                        }}
                    />
                    :
                    <Button
                        borderRadius={10}
                        fontSize={14}
                        px={3}
                        py={1}
                        mt={-2}
                        color={'white'}
                        bgGradient={'linear(to-r, red.400,pink.400)'}
                        onClick={() => {
                            dispatch(toggleIsChatVisible())
                        }}
                    >
                        Show PDF
                    </Button>}
            </Flex>

            {renderComponent()}
        </Box>
    );
}
