'use client'

import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    IconButton,
    Link,
    useColorModeValue, useToast, Button,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FaInstagram, FaTwitter, FaYoutube, FaTiktok } from 'react-icons/fa'
import { BiMailSend } from 'react-icons/bi'
import lightTheme from "../../utils/styles";
import {contactRoute} from "../../utils/api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useServerIP} from "../../redux/ServerIPContext";

const SocialButton = ({
                          children,
                          label,
                          href,
                      }: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

export default function LargeWithNewsletter() {
    const toast = useToast()
    const [emailAddress, setEmailAddress] = React.useState("")
    const navigate = useNavigate()
    const {t} = useTranslation()
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box as="span" cursor="pointer" mb={"-0.5rem"}>
                            <Text as="span" fontFamily="'Merriweather', serif" fontSize={'1.9rem'} fontWeight={'500'}>
                                honeybear
                            </Text>
                            <Text as="span" fontFamily="'Merriweather', serif" fontSize={'1.9rem'} fontWeight={'500'} color={lightTheme.colors.darkYellow2}>
                                .
                            </Text>
                            <Text as="span" fontFamily="'Merriweather', serif" fontSize={'1.9rem'} fontWeight={'500'}>
                                ai
                            </Text>
                            <Box as="span" bgColor="black" width="1.25px" height="1.92em" display="inline-block" ml={"0.08rem"} mb={'-0.23rem'}/>
                        </Box>
                        <Text fontSize={'sm'}>© 2023 honeybear.ai. All rights reserved.</Text>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'Twitter'} href={'https://twitter.com/honeybear_ai'}>
                                <FaTwitter />
                            </SocialButton>
                            <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@honeybear.ai'}>
                                <FaTiktok />
                            </SocialButton>
                            <SocialButton label={'Instagram'} href={'https://www.instagram.com/honeybear.ai/'}>
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t("Company")}</ListHeader>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    About us*/}
                        {/*</Box>*/}
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Blog*/}
                        {/*</Box>*/}
                        <a href="https://forms.gle/wop7LKi3qB96eBeW8" target="_blank" rel="noopener noreferrer">
                            {t("Contact us")}
                        </a>
                        <Box as="a" cursor={"pointer"} onClick={() => {
                            navigate("/pricing")
                            // toast({
                            //     title: "honeybear.ai is FREE!",
                            //     status: "info",
                            //     duration: 6000,
                            //     isClosable: true,
                            // })
                        }}>
                            {t("Pricing")}
                        </Box>
                        <a href="https://forms.gle/GD6UVZha7LMGLnjNA" target="_blank" rel="noopener noreferrer">
                            {t("Share Feedback")}
                        </a>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Testimonials*/}
                        {/*</Box>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t("Support")}</ListHeader>
                        <a href="https://forms.gle/wop7LKi3qB96eBeW8" target="_blank" rel="noopener noreferrer">
                            {t("Help")}
                        </a>
                        <Box as="a" href={'https://docs.google.com/document/d/1DXpWIW7B0ebKCHqfPlmnumI0qIoernblc5KUvZ1k79w/edit?usp=sharing'}>
                            {t("Terms of Service")}
                        </Box>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Legal*/}
                        {/*</Box>*/}
                        <Box as="a" href={'https://docs.google.com/document/d/1X4_5lAZoPksg_gppFKMTWvIdzBP5oypkaeMuPBbRU9k/edit?usp=sharing'}>
                            {t("Privacy Policy")}
                        </Box>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Satus*/}
                        {/*</Box>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{t("Get Updates")}</ListHeader>
                        <Stack direction={'row'}>
                            <Input
                                placeholder={t("Your email address")}
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                                border={0}
                                _focus={{
                                    bg: 'whiteAlpha.300',
                                }}
                            />
                            <IconButton
                                bg={useColorModeValue('orange.400', 'orange.800')}
                                color={useColorModeValue('white', 'gray.800')}
                                _hover={{
                                    bg: 'orange.600',
                                }}
                                aria-label="Subscribe"
                                onClick={() => {
                                    if (emailAddress === "") {
                                        toast({
                                            title: t("Please enter an email address"),
                                            status: "error",
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        return
                                    } else {
                                        contactRoute("email", emailAddress, serverIPs.SERVER_IP).then(r => {
                                            setEmailAddress("")
                                            toast({
                                                title: t("Thanks for subscribing!"),
                                                status: "success",
                                                duration: 5000,
                                                isClosable: true,
                                            })
                                        })
                                    }
                                }}
                                icon={<BiMailSend />}
                            />
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    )
}
