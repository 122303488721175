import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Spinner, useBreakpointValue, useDisclosure, useToast} from "@chakra-ui/react";
import PDFPage from "./PDFPage";
import {io} from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {SERVER_IP, SERVER_IP_SOCKET} from "../../utils/constants";
import {getSessionToken} from "../../utils/pdf-page-helpers";
import {useAuth} from "../../redux/AuthProvider";
import {setHighlightsInDocument, setMessagesInDocument, setMessagesInDocumentGeneration} from "../../redux/reducers";
import {MySpinnerLarge} from "../../components/Reuseable/MySpinner";
import ScanModal1 from "../../components/Modals/Scans/ScanModal1";
import PricingScreen from "../../components/Modals/Payments/PricingScreen";
import {useServerIP} from "../../redux/ServerIPContext";

export default function WrapperComponent ()  {
    const auth = useAuth();
    const toast = useToast();
    const [socket, setSocket] = useState(null);
    const { id } = useParams();
    const [sessionToken, setSessionToken] = useState("")
    const [currentDocument, setCurrentDocument] = useState(null);
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const documents = useSelector(state => state.documents.savedDocuments)
    // const documents = auth.isLoggedIn ? savedDocuments : auth.playgroundDocuments;

    const [forceRender, setForceRender] = useState(0);
    const [socketID, setSocketID] = useState("");
    const { isTyping, setIsTyping } = useAuth();
    const {isThinking, setIsThinking} = useAuth();
    const dispatch = useDispatch();
    const { isOpen: isOpenPricingScreen, onOpen: onOpenPricingScreen, onClose: onClosePricingScreen } = useDisclosure();
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base';

    const [suggestedQuestionsStream, setSuggestedQuestionsStream] = useState("");
    const [suggestedQuestions, setSuggestedQuestions] = useState([]);

    useEffect( () => {
        console.log("In UseEffect WrapperComponent with id:", id);
        const newSocket = io(serverIPs.SERVER_IP_SOCKET);
        setSocket(newSocket);
        console.log("Socket:", socket)
        newSocket.on('connected', function(data) {
            console.log("Connected:", data);
            setSocketID(newSocket.id);
        });
        newSocket.on('receive_suggested_question_message', function (data) {
            console.log("SUGGESTED QUESTIONS Data:", data);

            setSuggestedQuestionsStream((prevStream) => {
                const newStream = prevStream + data.message;

                // Check if we have a full array (i.e., contains both [ and ])
                const startIdx = newStream.indexOf("[");
                const endIdx = newStream.indexOf("]");

                if (startIdx !== -1 && endIdx !== -1 && startIdx < endIdx) {
                    // Extract the part inside the brackets
                    const extracted = newStream.substring(startIdx + 1, endIdx).trim();

                    // Use regex to capture all text between double quotes
                    const questionMatches = extracted.match(/"([^"]+)"/g);

                    // Clean up quotes and trim spaces
                    const questions = questionMatches ? questionMatches.map(q => q.replace(/"/g, "").trim()) : [];

                    console.log("Extracted Questions:", questions);

                    // Update state with the extracted questions
                    setSuggestedQuestions(questions);

                    // Clear stream since we have a full message processed
                    return "";
                }

                return newStream; // Keep accumulating until we get a full message
            });
        });
        return () => {
            console.log("Socket disconnecting", newSocket)
            newSocket.off('receive_suggested_question_message');
            newSocket.disconnect();
        }
    }, [id])

    useEffect(() => {
        console.log("In UseEffect WrapperComponent with socket:", socket);
        if (documents.length > 0) {
            console.log("Documents length > 0")
            setCurrentDocument(documents.find((document) => document.id === id));
            setForceRender(prevForceRender => prevForceRender + 1);
        }
    }, [documents.length, id]);

    useEffect(() => {
        console.log("In UseEffect WrapperComponent with currentDocument:", currentDocument);
        console.log("In UseEffect WrapperComponent with socketID:", socketID)
        console.log("In UseEffect WrapperComponent with sessionToken:", sessionToken)

        if (currentDocument && sessionToken === "" && socketID) {
            console.log("CurrentDocument is not null")
            const fetchSessionToken = async () => {
                const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                console.log("USERID", userID)
                const response = await getSessionToken(id, userID, socketID, serverIPs.SERVER_IP);
                if (response) {
                    console.log("RESPONSE FROM START SESSION", response);
                    if (response.doc_metadata.highlights) {
                        dispatch(setHighlightsInDocument({documentID: currentDocument.id,
                            newHighlights: response.doc_metadata.highlights.slice().reverse()}));
                    }
                    if (response.doc_metadata.messages) {
                        dispatch(setMessagesInDocument({documentID: currentDocument.id, newMessages: response.doc_metadata.messages}));
                        dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
                    }
                    dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
                    setSessionToken(response.sessionToken);
                }
            };
            fetchSessionToken().then(r => {});
        } else if (socketID === "" && currentDocument && sessionToken === "") {
            console.log("CurrentDocument is not null")
            const fetchSessionToken = async () => {
                const userID = auth.isLoggedIn ? localStorage.getItem("userID") : "11111111-1111-1111-1111-111111111111"
                console.log("USERID", userID)
                const response = await getSessionToken(id, userID, null, serverIPs.SERVER_IP);
                if (response) {
                    console.log("RESPONSE FROM START SESSION", response);
                    if (response.doc_metadata.highlights) {
                        dispatch(setHighlightsInDocument({documentID: currentDocument.id,
                            newHighlights: response.doc_metadata.highlights.slice().reverse()}));
                    }
                    if (response.doc_metadata.messages) {
                        dispatch(setMessagesInDocument({documentID: currentDocument.id, newMessages: response.doc_metadata.messages}));
                        dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
                    }
                    dispatch(setMessagesInDocumentGeneration({documentID: currentDocument.id, newMessages: []}));
                    setSessionToken(response.sessionToken);
                }
            };
            fetchSessionToken().then(r => {});
        }
    }, [currentDocument, socketID]);

    return (
        sessionToken === "" ?
            <div style={{justifyContent:'center', alignItems:'center', display:'flex', height:'100%', width:'100%', paddingTop:"5rem"}}>
                <MySpinnerLarge/>
            </div>
            :
            <div>
                <PDFPage document={documents.find((document) => document.id === id)}
                         isMobile={isMobile}
                         key={forceRender}
                         documents={documents}
                         id={id}
                         isLoggedIn={auth.isLoggedIn}
                         sessionToken={sessionToken}
                         socketID={socketID}
                         socket={socket}
                         isTyping={isTyping} setIsTyping={setIsTyping}
                         isThinking={isThinking} setIsThinking={setIsThinking}
                         toast={toast} suggestedQuestions={suggestedQuestions}
                         serverIP={serverIPs.SERVER_IP}/>
                <PricingScreen isOpenPricingScreen={isOpenPricingScreen}
                               onClosePricingScreen={onClosePricingScreen}
                               onOpenPricingScreen={onOpenPricingScreen}/>
            </div>
    )
}







