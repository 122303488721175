import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Grid,
    GridItem,
    VStack,
    Text,
    HStack,
    useDisclosure, useToast, Button, useBreakpointValue, Image, Icon,
} from '@chakra-ui/react';
import {useTranslation} from "react-i18next";

const DocumentGridAudiobooksEmpty = () => {
    const {t} = useTranslation();

    return (
        <VStack width={['95%','88%', '78%', '68%', '58%']}>
            <VStack spacing={4} width={'100%'} bg={'white'} mb={'10rem'}>
                <Text fontSize={18} fontWeight={'bold'}>{t("No Audiobooks yet")}</Text>
                <Text color={"gray.700"} textAlign={'center'}>{t("Upload a PDF, then get a personalized audiobook!")}</Text>
                {/*<Text color={"gray.700"}>{t("Extract key information, summarize, generate notes or practice questions, and much more.")}</Text>*/}
            </VStack>
        </VStack>
    );
};

export default DocumentGridAudiobooksEmpty;
