import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast
} from "@chakra-ui/react";
import GoogleButton from "../../buttons/GoogleButton";
import {handleSignUp} from "../../../utils/auth-helpers";
import {deleteDocument, deleteGeneration} from "../../../utils/home-page-helpers";
import {fetchDocuments, fetchGenerations} from "../../../utils/redux-helpers";
import {setDocuments, setDocumentsGeneration, setFilesInDocumentGeneration} from "../../../redux/reducers";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {deleteMultidoc, fetchMultidocs, getMultidoc, removeSourceMultidoc} from "../../../utils/multi-doc-helpers";
import {useServerIP} from "../../../redux/ServerIPContext";

const DeleteScreenMultidocSource = ({ isOpenDeleteMultidocSource, onCloseDeleteMultidocSource, title,
                                        userID, fileID, multidocID, multidocSessionID, generation}) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const deleteAction = async () => {
        setIsLoading(true)

        // removeSourceMultidoc = async (userID, fileID, multidocID, multidocSessionID)
        await removeSourceMultidoc(userID, fileID, multidocID, multidocSessionID, serverIPs.SERVER_IP_MULTIDOC).then((response) => {
            setIsLoading(false)
            if (response === null) {
                toast({
                    title: t('Oops, something went wrong. Please try again.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t('401 error. Please try again.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                getMultidoc(multidocID, userID, serverIPs.SERVER_IP_MULTIDOC).then((getResponse) => {
                    if (getResponse) {
                        console.log("RESPONSE FROM GET MULTIDOC", getResponse);
                        if (getResponse.files) {
                            const files = getResponse.files.map((file) => {
                                return {
                                    id: file.file_id,
                                    name: file.file_name,
                                    url: file.url,
                                    isActive: generation.files.find(f => f.id === file.file_id) !== undefined ? generation.files.find(f => f.id === file.file_id).isActive : true
                                }
                            });
                            dispatch(setFilesInDocumentGeneration({documentID: multidocID, newFiles: files}));
                        }
                    }
                });
                toast({
                    title: t('Successfully deleted.'),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                onCloseDeleteMultidocSource();
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpenDeleteMultidocSource} onClose={onCloseDeleteMultidocSource} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="18rem" w="26rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseDeleteMultidocSource}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="xl" fontWeight="bold">{t('Are You Sure?')}</Text>
                            <Text fontSize="sm" fontWeight="light" textAlign={"center"}>
                                The source <b>{title}</b> will be deleted. You can always reupload it to this workspace later.
                            </Text>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4}>
                            <Stack spacing={10} pt={4}>
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    isLoading={isLoading}
                                    bg={'red.400'}
                                    color={'white'}
                                    onClick={deleteAction}
                                    _hover={{
                                        bg: 'red.500',
                                    }}>
                                    {t('Delete Source')}
                                </Button>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeleteScreenMultidocSource;








