import React, { createContext, useContext, useState, useEffect } from "react";

const ServerIPContext = createContext();

export const ServerIPProvider = ({ children }) => {
    const hostname = window.location.hostname;
    const isDev = hostname === "dev.honeybear.ai" || hostname === "localhost";

    // Default IPs
    const defaultIPs = {
        SERVER_IP: "https://100.24.205.128.nip.io",
        SERVER_IP_MULTIDOC: "https://100.24.205.128.nip.io",
        SERVER_IP_VIDEO: "https://3.89.226.93.nip.io",
        SERVER_IP_SOCKET: "https://100.24.205.128.nip.io",
    };

    // Load persisted values from localStorage
    const getPersistedIPs = () => {
        const savedIPs = localStorage.getItem("serverIPs");
        return savedIPs ? JSON.parse(savedIPs) : defaultIPs;
    };

    const [serverIPs, setServerIPs] = useState(getPersistedIPs);

    useEffect(() => {
        localStorage.setItem("serverIPs", JSON.stringify(serverIPs));
    }, [serverIPs]);

    return (
        <ServerIPContext.Provider value={{ serverIPs, setServerIPs, isDev }}>
            {children}
        </ServerIPContext.Provider>
    );
};

export const useServerIP = () => useContext(ServerIPContext);
