import {
    Box,
    Text,
    Image,
    HStack,
    Avatar,
    useToast,
    IconButton,
    Icon,
    Heading,
    ListItem,
    UnorderedList, OrderedList, Td, Th, Table, Thead, Tbody, Tr, Link, Tooltip,
} from "@chakra-ui/react";
import {whichMessageHeader} from "../../utils/pdf-page-helpers";
import React from "react";
import {ThreeDotLoading} from "../Reuseable/ThreeDotLoading";
import {useAuth} from "../../redux/AuthProvider";
import FollowUpButton from "./FollowUpButton";
import lightTheme from "../../utils/styles";
import {FiCopy, FiCornerDownRight} from "react-icons/fi";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks';
import {markdownComponents} from "../../utils/constants";
import {useTranslation} from "react-i18next";

export const MessageBubble = ({ message, isLastMessage, userBgColor, claudeBgColor, claudeColor,
                                  lastMessageRef, sessionToken, socketID }) => {
    const auth = useAuth();
    const toast = useToast();
    const [firstName, lastName] = [localStorage.getItem("firstName"), localStorage.getItem("lastName")];
    const {t} = useTranslation();

    const whichMessage = (message) => {
        if (message.user === 'User') {
            if (message.type.includes("selection")) {
                return <Text>{message.selection_text}</Text>
            } else if (message.type.includes("follow_up")) {
                if (message.selection_text) {
                    const formattedMessage = message.selection_text;
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={formattedMessage} />
                } else {
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={message.selection_text} />
                }
            }
            else {
                if (message.question) {
                    const formattedMessage = message.question;
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={formattedMessage} />
                } else {
                    return <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                        children={message.question} />
                }
            }
        } else if (message.user === 'Claude') {
            if (message.response) {
                const formattedMessage = message.response.replace(/\n{2,}/g, '\n&nbsp;\n')
                    // Replace single newline with a line break
                    .replace(/\n/g, '  \n');
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={formattedMessage} />
            } else {
                return <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                    children={message.response} />
            }
        }
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast({
                    title: t("Copied!"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    }


    if (message.user === "Claude" && message.response === "") {
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? userBgColor : claudeBgColor}
                color={message.user.toLowerCase() === 'claude' ? claudeColor : 'white'}
                alignSelf={message.user.toLowerCase() === 'user' ? 'flex-end' : 'flex-start'}
                p='1.5rem'
                ref={isLastMessage ? lastMessageRef : null}
                width="100%"
                justifyContent={"flex-start"}
                alignItems={"center"}
                flexDirection={"row"}
                display={"flex"}
            >
                <Box
                    width="2rem"
                    height="2rem"
                    borderRadius="full"
                    overflow="hidden"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    mr="1rem"
                >
                    <Image
                        src='/always_white_background_h_logo.png'
                        alt="Company Logo"
                        width="100%"
                        height="100%"
                        transform="scale(1.1)"
                        objectFit="cover"
                    />
                </Box>
                <ThreeDotLoading/>
            </Box>
        )
    }

    else if (message.type === "follow_up") {
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? "white" : claudeBgColor}
                color={message.user.toLowerCase() === 'claude' ? claudeColor : 'black'}
                p='1.5rem'
                ref={isLastMessage ? lastMessageRef : null}
                width="100%"
                alignItems={"flex-start"}
                flexDirection={"row"}
                display={"flex"}
            >
                <Box
                    width="2rem"  // Adjust the width as needed
                    height="2rem"  // Adjust the height as needed
                    borderRadius="full"  // Makes the image circular
                    overflow="hidden"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    mr="1rem"
                >
                    {auth.isLoggedIn && message.user === "User" ?
                        <Avatar name={firstName + " " + lastName} width={"100%"} height={"100%"} size={"sm"}/> :
                        !auth.isLoggedIn && message.user === "User" ?
                            <Image
                                src={"https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg"}
                                alt="Company Logo"
                                width="100%"
                                height="100%"
                                objectFit="cover"
                            /> :
                            <Image
                                src='/always_white_background_h_logo.png'
                                alt="Company Logo"
                                width="100%"
                                height="100%"
                                transform="scale(1.1)"
                                objectFit="cover"
                            />}
                </Box>
                <Box display={'flex'} flexDirection={"column"} flex="1">
                    <div style={{marginBottom: "0.8rem", backgroundColor:lightTheme.colors.lightGray,
                        padding:"0.6rem", borderRadius:'0.8rem', paddingLeft:'1.1rem'}}>
                        {whichMessage(message)}
                    </div>

                    {message.user.toLowerCase() === 'user' && message.type !== "general_question" && (
                        <HStack display={"flex"} flexDirection={"row"} spacing={"0.5rem"}>
                            <FiCornerDownRight/>
                            <Text fontWeight={"700"} color={'black'} >
                                {whichMessageHeader(message.type, message)}</Text>
                        </HStack>
                    )}


                    <Box display={'flex'} flexDirection={"row"} alignItems={'center'}>
                        {(message.user === "Claude" && !auth.isThinking && !isLastMessage) ? <FollowUpButton
                            sessionToken={sessionToken} socketID={socketID}
                            claudeMessage={message}/> : null}
                        {message.user === "Claude" && !auth.isThinking ?
                            <Tooltip hasArrow label={t("Copy this response")} bg='black' color="white" placement="right">
                                <span>
                                    <Icon
                                        as={FiCopy}
                                        boxSize="1.4rem" // or any other size you desire
                                        cursor="pointer"
                                        mt={'1rem'}
                                        ml={isLastMessage ? '0.1rem' : '1.5rem'}
                                        onClick={() => copyToClipboard(message.response)}
                                    />
                                </span>
                            </Tooltip>
                            : null}
                    </Box>
                </Box>
            </Box>
        )
    }

    else {
        return (
            <Box
                key={message.id}
                bg={message.user.toLowerCase() === 'user' ? "white" : claudeBgColor}
                color={message.user.toLowerCase() === 'claude' ? claudeColor : 'black'}
                p='1.5rem'
                ref={isLastMessage ? lastMessageRef : null}
                width="100%"
                alignItems={"flex-start"}
                flexDirection={"row"}
                display={"flex"}
            >
                <Box
                    width="2rem"  // Adjust the width as needed
                    height="2rem"  // Adjust the height as needed
                    borderRadius="full"  // Makes the image circular
                    overflow="hidden"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    mr="1rem"
                >
                    {auth.isLoggedIn && message.user === "User" ?
                        <Avatar name={firstName + " " + lastName} width={"100%"} height={"100%"} size={"sm"}/> :
                        !auth.isLoggedIn && message.user === "User" ?
                            <Image
                                src={"https://static.vecteezy.com/system/resources/thumbnails/005/544/718/small/profile-icon-design-free-vector.jpg"}
                                alt="Company Logo"
                                width="100%"
                                height="100%"
                                objectFit="cover"
                            /> :
                            <Image
                                src='/always_white_background_h_logo.png'
                                alt="Company Logo"
                                width="100%"
                                height="100%"
                                transform="scale(1.1)"
                                objectFit="cover"
                            />}



                </Box>
                <Box display={'flex'} flexDirection={"column"} flex="1">
                    {message.user.toLowerCase() === 'user' && message.type !== "general_question" && (
                        <Text fontWeight={"700"} color={'black'} marginBottom={'0.8rem'}>
                            {whichMessageHeader(message.type, message)}</Text>
                    )}
                    {message.type === "ask_image" && message.user === "User" ?
                        <Image src={message.selection_text} alt="Image asked about" width="100%" height="100%"
                               objectFit="cover"/>
                        :
                        whichMessage(message)
                    }


                    <Box display={'flex'} flexDirection={"row"} alignItems={'center'}>
                        {(message.user === "Claude" && !auth.isThinking && !isLastMessage) ? <FollowUpButton
                            sessionToken={sessionToken} socketID={socketID}
                            claudeMessage={message}/> : null}
                        {message.user === "Claude" && !auth.isThinking ?
                            <Tooltip hasArrow label={t("Copy this response")} bg='black' color="white" placement={'right'}>
                                <span>
                                    <Icon
                                        as={FiCopy}
                                        boxSize="1.4rem" // or any other size you desire
                                        cursor="pointer"
                                        mt={'1rem'}
                                        ml={isLastMessage ? '0.1rem' : '1.5rem'}
                                        onClick={() => copyToClipboard(message.response)}
                                    />
                                </span>
                            </Tooltip>
                            : null}
                    </Box>

                </Box>
            </Box>
        )
    }
}





