import React, { useState, useEffect } from "react";
import { Select, Box, Text, Input, useToast } from "@chakra-ui/react";
import { useServerIP } from "../../redux/ServerIPContext";

const ServerIPSelector = () => {
    const { serverIPs, setServerIPs, isDev } = useServerIP();
    const [customInputs, setCustomInputs] = useState({});
    const toast = useToast();

    useEffect(() => {
        // Initialize the custom input state with existing values (defaults or persisted)
        setCustomInputs({
            SERVER_IP: serverIPs.SERVER_IP,
            SERVER_IP_MULTIDOC: serverIPs.SERVER_IP_MULTIDOC,
            SERVER_IP_VIDEO: serverIPs.SERVER_IP_VIDEO,
            SERVER_IP_SOCKET: serverIPs.SERVER_IP_SOCKET,
        });
    }, [serverIPs]);

    const options = [
        "https://34.226.208.115.nip.io",
        "https://100.24.205.128.nip.io",
        "https://3.89.226.93.nip.io",
        "34.226.208.115.nip.io",
        "100.24.205.128.nip.io",
        "3.89.226.93.nip.io",
        "custom", // Allows users to enter a custom URL
    ];

    const handleChange = (key, value) => {
        if (value === "custom") {
            setCustomInputs((prev) => ({ ...prev, [key]: "" })); // Reset input field
            setServerIPs((prev) => ({ ...prev, [key]: "custom" })); // Keep "custom" selected
        } else {
            setCustomInputs((prev) => ({ ...prev, [key]: value })); // Sync state
            setServerIPs((prev) => {
                const newIPs = { ...prev, [key]: value };
                localStorage.setItem("serverIPs", JSON.stringify(newIPs)); // Persist change
                return newIPs;
            });
        }
    };

    const handleCustomInputChange = (key, event) => {
        setCustomInputs((prev) => ({ ...prev, [key]: event.target.value }));
    };

    const handleCustomInputSubmit = (key, event) => {
        if (event.key === "Enter") {
            setServerIPs((prev) => {
                const newIPs = { ...prev, [key]: customInputs[key] };
                localStorage.setItem("serverIPs", JSON.stringify(newIPs)); // Persist change
                return newIPs;
            });

            toast({
                title: `${key} updated successfully!`,
                description: `New URL: ${customInputs[key]}`,
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
        }
    };

    const getDropdownValue = (key) => {
        return options.includes(serverIPs[key]) ? serverIPs[key] : "custom";
    };

    if (!isDev) return null; // Only show in dev mode

    return (
        <Box display="flex" gap="8px" flexDirection="column">
            {["SERVER_IP", "SERVER_IP_MULTIDOC", "SERVER_IP_VIDEO", "SERVER_IP_SOCKET"].map((key) => (
                <Box key={key}>
                    <Text fontSize="sm" fontWeight={'bold'}>{key}</Text>
                    <Select
                        value={getDropdownValue(key)}
                        onChange={(e) => handleChange(key, e.target.value)}
                    >
                        {options.map((url) => (
                            <option key={url} value={url}>
                                {url === "custom" ? "Enter Custom URL" : url}
                            </option>
                        ))}
                    </Select>
                    {getDropdownValue(key) === "custom" && (
                        <Input
                            placeholder={`Enter ${key} URL`}
                            value={customInputs[key]}
                            onChange={(e) => handleCustomInputChange(key, e)}
                            onKeyDown={(e) => handleCustomInputSubmit(key, e)}
                            mt="5px"
                        />
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default ServerIPSelector;
