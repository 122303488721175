import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast
} from "@chakra-ui/react";
import GoogleButton from "../../buttons/GoogleButton";
import {handleSignUp} from "../../../utils/auth-helpers";
import {deleteAccount, deleteDocument} from "../../../utils/home-page-helpers";
import {fetchDocuments} from "../../../utils/redux-helpers";
import {setDocuments} from "../../../redux/reducers";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../redux/AuthProvider";
import {useTranslation} from "react-i18next";
import {useServerIP} from "../../../redux/ServerIPContext";

const DeleteAccount = ({ isOpenDelete1, onCloseDelete1, userID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useAuth();
    const {t} = useTranslation();
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const deleteAction = async () => {
        setIsLoading(true)
        await deleteAccount(userID, serverIPs.SERVER_IP).then((response) => {
            setIsLoading(false)
            if (response === null) {
                toast({
                    title: t('Oops, something went wrong. Please try again.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t('User not found. Please try again.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                toast({
                    title: t('Your account has been deleted.'),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
                auth.logout();


                const wasUploadExceeded = localStorage.getItem('isUploadExceeded') ? JSON.parse(localStorage.getItem('isUploadExceeded')) : null;
                const wasChatExceeded = localStorage.getItem('isChatExceeded') ? JSON.parse(localStorage.getItem('isChatExceeded')) : null;

                localStorage.clear();

                // Conditionally set them back if they existed
                if (wasUploadExceeded !== null) {
                    localStorage.setItem('isUploadExceeded', JSON.stringify(wasUploadExceeded));
                }
                if (wasChatExceeded !== null) {
                    localStorage.setItem('isChatExceeded', JSON.stringify(wasChatExceeded));
                }

                navigate('/')
                window.location.reload();
                onCloseDelete1();
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpenDelete1} onClose={onCloseDelete1} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="18rem" w="26rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseDelete1}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={4}>
                            <Text fontSize="xl" fontWeight="bold">{t('Are You Sure?')}</Text>
                            <Text fontSize="sm" fontWeight="light" textAlign={"center"}>
                                {t('Your account and all its data will be deleted.')}
                            </Text>
                            <Text fontSize="lg" fontWeight="light" textAlign={"center"}>
                                {t('Note:')} <Text as="span" fontWeight="bold">{t('You cannot sign up with the same email again')}</Text>
                            </Text>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4}>
                            <Stack spacing={10} pt={4}>
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    isLoading={isLoading}
                                    bg={'red.400'}
                                    color={'white'}
                                    onClick={deleteAction}
                                    _hover={{
                                        bg: 'red.500',
                                    }}>
                                    {t('Delete Account')}
                                </Button>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeleteAccount;








