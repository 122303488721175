// PaymentStatus.js
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Box, Button, Icon, Text, VStack} from '@chakra-ui/react';
import Confetti from 'react-confetti';
import animationDataCheckmark from "../../components/Modals/Scans/lottie-checkmark.json";
import Lottie from "lottie-react";
import {FcCancel, FcDocument} from "react-icons/fc";
import {createCheckoutSession} from "../../utils/payments";
import {useAuth} from "../../redux/AuthProvider";
import {useTranslation} from "react-i18next";
import {useServerIP} from "../../redux/ServerIPContext";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CheckoutResponse() {
    const query = useQuery();
    const sessionId = query.get('session_id');
    const success = query.get('success');
    const canceled = query.get('canceled');
    const navigate = useNavigate();
    const [premiumLoading, setPremiumLoading] = useState(false);
    const auth = useAuth();
    const {t} = useTranslation();
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    console.log(sessionId, success, canceled)

    useEffect(() => {
        if (success) {
            console.log("Celebration time!");
            window.rewardful('convert', { email: localStorage.getItem("email") });
            console.log("Celebration time 2!", window.rewardful);

            window.gtag('event', 'conversion_event_subscribe_paid', {
                event_category: 'ecommerce',
                event_label: 'pro_plan',
                value: 10.00,
                currency: 'USD',
                transaction_id: sessionId,
                items: [
                    {
                        item_id: 'pro_plan',
                        item_name: 'Honeybear.ai Pro Plan Subscription',
                        item_category: 'Subscription',
                        quantity: 1,
                        price: 10.00,  // or the actual price of the subscription
                    }
                ]
            });
        }
    }, [success, sessionId]);

    return (
        <Box justifyContent={'center'} alignItems={'flex-start'} bg={'white'} width={'100vw'} height={'100vh'} display={'flex'}>
            {success && sessionId && (
                <>
                    <Confetti width={window.innerWidth} height={window.innerHeight} recycle={false} gravity={0.6}/>
                    <VStack spacing={6} mt={'6rem'}>
                        <Lottie
                            animationData={animationDataCheckmark}
                            loop={false}
                            autoplay={true}
                            style={{ width: '30%', height: '30%' }}
                        />
                        <VStack spacing={4}>
                            <Text fontSize={'2xl'} fontWeight={'bold'}>{t("Payment successful!")}</Text>
                            <Text fontSize={'lg'}>{t("Great news! Your Pro Plan is now active.")}</Text>
                        </VStack>
                        <Button
                            loadingText="Submitting"
                            size="lg"
                            mt={2}
                            isLoading={false}
                            onClick={() => {
                                navigate('/')
                            }}
                            rounded={'full'}
                            px={6}
                            colorScheme={'orange'}
                            bg={'orange.400'}
                            _hover={{ bg: 'orange.500' }}
                        >
                            {t("Back to Home")}
                        </Button>
                    </VStack>
                </>
            )}
            {canceled && (
                <VStack spacing={6} mt={'6rem'}>
                    <Icon as={FcCancel} w={20} h={20} />
                    <VStack spacing={4}>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>{t("Payment Canceled")}</Text>
                        <Text fontSize={'lg'}>{t("You canceled this payment.")}</Text>
                    </VStack>
                    <Button
                        size="lg"
                        mt={2}
                        isLoading={premiumLoading}
                        isDisabled={premiumLoading}
                        onClick={async () => {
                            setPremiumLoading(true)
                            const userID = auth.isLoggedIn ? localStorage.getItem("userID")
                                : "11111111-1111-1111-1111-111111111111"
                            const userEmail = localStorage.getItem("email")
                            await createCheckoutSession(userID, userEmail, serverIPs.SERVER_IP).then(r => {
                                setPremiumLoading(false)
                                window.location.href = r.url;
                            })
                        }}
                        rounded={'full'}
                        px={6}
                        colorScheme={'orange'}
                        bg={'orange.400'}
                        _hover={{ bg: 'orange.500' }}
                    >
                        {t("Try again")}
                    </Button>
                </VStack>
            )}
        </Box>
    );
}

export default CheckoutResponse;
