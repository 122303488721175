// import React, { useState, useRef, useEffect } from "react";
// import { Box, VStack, Button, Text } from "@chakra-ui/react";
//
// const OutlineButton = ({ heading, onClick }) => {
//     const [isHovered, setIsHovered] = useState(false);
//     const [buttonWidth, setButtonWidth] = useState("100%");
//     const buttonRef = useRef(null);
//
//     useEffect(() => {
//         if (buttonRef.current) {
//             const textWidth = buttonRef.current.scrollWidth; // Full text width
//             const containerWidth = buttonRef.current.clientWidth; // Current visible width
//
//             // Set width to max of 100% or text width
//             setButtonWidth(`${Math.max(textWidth, containerWidth)}px`);
//         }
//     }, [heading]);
//
//     return (
//         <Box position="relative" width="100%">
//             <Button
//                 ref={buttonRef}
//                 p={2}
//                 width={isHovered ? buttonWidth : "100%"}
//                 textAlign="left"
//                 overflow="hidden"
//                 whiteSpace="nowrap"
//                 textOverflow="ellipsis"
//                 variant="ghost"
//                 size="sm"
//                 _hover={{ bg: "black", color:'white' }}
//                 onClick={onClick}
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//             >
//                 <Text
//                     textAlign="left"
//                     width="100%"
//                     whiteSpace="nowrap"
//                     textOverflow={isHovered ? "clip" : "ellipsis"} // ✅ Show ellipsis when not hovered
//                 >
//                     {heading}
//                 </Text>
//             </Button>
//         </Box>
//     );
// };
//
// export default OutlineButton;



import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Text } from "@chakra-ui/react";

const OutlineButton = ({ heading, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const buttonRef = useRef(null);
    const [buttonWidth, setButtonWidth] = useState("100%");
    const [buttonHeight, setButtonHeight] = useState("auto");

    useEffect(() => {
        if (buttonRef.current) {
            const textWidth = buttonRef.current.scrollWidth; // Full text width
            const containerWidth = buttonRef.current.clientWidth; // Current visible width

            // Set width to max of 100% or text width
            setButtonWidth(`${Math.max(textWidth, containerWidth)}px`);
        }
    }, [heading]);

    return (
        <Box position="relative" width="100%">
            <Button
                ref={buttonRef}
                p={2}
                width={isHovered ? buttonWidth : "100%"} // ✅ Expand beyond outline
                // maxWidth={isHovered ? "100%" : "100%"}  // ✅ Limit expansion to 300px
                height={isHovered ? "auto" : "2rem"}  // ✅ Allow height to adjust
                textAlign="left"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                variant="ghost"
                size="sm"
                _hover={{ bg: "black", color: "white" }}
                onClick={onClick}
                onMouseEnter={() => {
                    setIsHovered(true);
                    setButtonHeight("auto"); // ✅ Expand button height to fit text
                }}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setButtonHeight("2rem"); // ✅ Reset height when not hovered
                }}
            >
                <Text
                    textAlign="left"
                    width="100%"
                    whiteSpace={isHovered ? "normal" : "nowrap"} // ✅ Wrap text when hovered
                    textOverflow={isHovered ? "clip" : "ellipsis"} // ✅ Show ellipsis when not hovered
                    overflow="hidden"
                    display="-webkit-box"
                    sx={{
                        WebkitLineClamp: isHovered ? 4 : "unset", // ✅ Restrict to 3 lines on hover
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {heading}
                </Text>
            </Button>
        </Box>
    );
};

export default OutlineButton;
