import React, { useEffect, useState, useRef } from "react";
import { fetchWavBuffer, combineWavBuffers } from "../../utils/audioUtils";
import AudioPlayer from "./AudioPlayer";

const AudioCombiner = ({ pageAudioURLs, onTimeUpdate, onDuration, forwardedRef }) => {
    const [combinedAudioUrl, setCombinedAudioUrl] = useState(null);
    const [duration, setDuration] = useState(0);

    // Update duration using an Audio element.
    const updateDuration = (dataUrl) => {
        const audio = new Audio(dataUrl);
        audio.addEventListener("loadedmetadata", () => {
            setDuration(audio.duration);
            if (typeof onDuration === "function") {
                       onDuration(audio.duration);
                     }
        });
    };

    useEffect(() => {
        const combineAll = async () => {
            if (pageAudioURLs.length === 0) return;
            let currentBuffer = await fetchWavBuffer(pageAudioURLs[0]);
            let currentResult = null;
            for (let i = 1; i < pageAudioURLs.length; i++) {
                const newBuffer = await fetchWavBuffer(pageAudioURLs[i]);
                const result = await combineWavBuffers(currentBuffer, newBuffer);
                currentBuffer = result.combinedBuffer;
                currentResult = result;
            }
            const finalResult = currentResult || await combineWavBuffers(new Uint8Array().buffer, currentBuffer);
            setCombinedAudioUrl(finalResult.dataUrl);
            updateDuration(finalResult.dataUrl);
        };
        combineAll();
    }, [pageAudioURLs]);

    return combinedAudioUrl ? (
        <AudioPlayer
            ref={forwardedRef}
            audioSrc={combinedAudioUrl}
            duration={Math.floor(duration)}
            onTimeUpdate={onTimeUpdate}
        />
    ) : (
        <p>Loading audio...</p>
    );
};

export default AudioCombiner;
