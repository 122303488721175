import { useState, useRef, useEffect } from "react";
import {
    Box,
    Button,
    IconButton,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
    HStack,
    VStack,
    Switch,
    Divider,
    Collapse
} from "@chakra-ui/react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { CloseIcon } from "@chakra-ui/icons";

const SpeedControl = ({ speed, setSpeed, isOpen, duration, onClose }) => {
    const boostMap = {
        1.1: 7,
        1.2: 18,
        1.3: 27,
        1.4: 38,
        1.5: 48,
        1.6: 57,
        1.7: 67,
        1.8: 76,
        1.9: 86,
        2.0: 95,
        2.1: 105,
        2.2: 114,
        2.3: 124,
        2.4: 133,
        2.5: 143,
        2.6: 152,
        2.7: 162,
        2.8: 171,
        2.9: 181,
        3.0: 190,
    };

    const roundedSpeed = parseFloat(speed.toFixed(1));
    const boost = boostMap[roundedSpeed];

    let speedLabel = "";
    if (roundedSpeed <= 0.9) {
        speedLabel = "Slow";
    } else if (roundedSpeed >= 1.0 && roundedSpeed <= 1.4) {
        speedLabel = "Normal";
    } else if (roundedSpeed >= 1.5 && roundedSpeed <= 2.4) {
        speedLabel = "Fast";
    } else if (roundedSpeed >= 2.5) {
        speedLabel = "Speed Reader";
    }

    const formatDuration = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${hrs > 0 ? `${hrs}:` : ""}${hrs > 0 ? String(mins).padStart(2, "0") : mins}:${String(secs).padStart(2, "0")}`;
    };


    return (
        <Collapse in={isOpen} animateOpacity>
            <Box
                position="absolute"
                bottom="87%"
                left="50%"
                transform="translateX(-50%)"
                width="100%"
                bg="black"
                color="white"
                borderBottomLeftRadius={0}
                borderBottomRightRadius={0}
                borderTopRightRadius={22}
                borderTopLeftRadius={22}
                py={6}
                px={12}
                boxShadow="xl"
                zIndex="1100"
            >
                <IconButton
                    icon={<CloseIcon boxSize={3} />}
                    aria-label="Close"
                    onClick={onClose}
                    size="sm"
                    position="absolute"
                    top={3}
                    right={3}
                    bg="gray.800"
                    color="white"
                    _hover={{ bg: "gray.700" }}
                    _active={{ bg: "gray.600" }}
                    borderRadius="full"
                    zIndex="1101"
                />

                <Text fontSize="md" fontWeight="bold" textAlign="center">Select Speed</Text>

                <HStack width={'100%'} justify={'space-around'} mt={8} spacing={6} height={'210px'} align={'flex-start'}>
                    <VStack spacing={4}>
                        <VStack>
                            <Text fontSize={18} fontWeight="600" textAlign="center">{speedLabel}</Text>
                            <Text fontSize={13} fontWeight={'600'} textAlign="center" color="gray.400">
                                Duration: ~{formatDuration(duration / speed)}
                            </Text>
                        </VStack>

                        <VStack spacing={4}>
                            {boost ?
                                <Box
                                    bg="rgba(0, 128, 0, 0.25)" // semi-transparent dark green
                                    p={2}
                                    borderRadius="md"
                                    textAlign="center"
                                    border="0.5px solid #38A169" // Chakra's green.500
                                >
                                    <Text
                                        fontSize="xs"
                                        color="green.300"
                                        fontWeight="600"
                                        textShadow="0 0 4px rgba(56, 161, 105, 0.6)" // soft glow
                                    >
                                        {boost}% productivity boost
                                    </Text>
                                </Box>
                                :
                                <Box bg="black" p={2} borderRadius="md" textAlign="center">
                                    <Text fontSize="xs" color={'black'}>{boost}% productivity boost</Text>
                                </Box>}

                            {/* Speed Adjust */}
                            <HStack justify="center" spacing={3}>
                                <Button
                                    onClick={() => setSpeed(Math.max(0.5, speed - 0.1))}
                                    color="white"
                                    bg="gray.900"
                                    borderRadius="full"
                                    boxSize="33px"
                                    fontSize={13.5}
                                    _hover={{ bg: "gray.800" }}
                                    _active={{ bg: "gray.700" }}
                                    borderColor="gray"
                                    borderWidth="0.25px"
                                    padding={0}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <FaMinus style={{ margin: 0, padding: 0, display: "block" }} />
                                </Button>
                                <Text fontSize={20} fontWeight="bold">{speed.toFixed(1)}x</Text>
                                <Button
                                    onClick={() => setSpeed(Math.min(3.0, speed + 0.1))}
                                    color="white"
                                    bg="gray.900"
                                    borderRadius="full"
                                    boxSize="33px"
                                    fontSize={13.5}
                                    _hover={{ bg: "gray.800" }}
                                    _active={{ bg: "gray.700" }}
                                    borderColor="gray"
                                    borderWidth="0.25px"
                                    padding={0}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <FaPlus style={{ margin: 0, padding: 0, display: "block" }} />
                                </Button>
                            </HStack>

                            <Text fontSize="xs" fontWeight={'600'} textAlign="center" color="gray.400">
                                {Math.round(200 * speed)} words per minute
                            </Text>
                        </VStack>
                    </VStack>


                    <Box display="flex" justifyContent="center" height={'100%'}>
                        <Slider
                            orientation="vertical"
                            height="100%"
                            min={0.5}
                            max={3.0}
                            step={0.1}
                            value={speed}
                            onChange={(val) => setSpeed(val)}
                        >
                            <SliderTrack bg="gray.600" width="30px" borderRadius={8}>  {/* Adjust width here */}
                                <SliderFilledTrack bg="blue.400" />
                            </SliderTrack>
                            <SliderThumb width="30px"  // Make it wider horizontally
                                         height="8px"   // Make it thinner vertically
                                         borderRadius="full"
                            />
                        </Slider>
                    </Box>
                </HStack>





            </Box>
        </Collapse>
    );
};

export default SpeedControl;
