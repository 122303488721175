import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    VStack,
    HStack,
    Text,
    IconButton,
    useColorModeValue,
    SkeletonText,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import OutlineButton from "./OutlineButton";
import axios from "axios";
import { useServerIP } from "../../redux/ServerIPContext";
import { addAudioInAudiobook } from "../../redux/reducers";
import {useDispatch, useSelector} from "react-redux";
import AudioCombiner from "../../pages/PDFPage/AudioCombiner";
import {createAudiobook, getOutline} from "../../utils/audiobook-helpers";
import StreamingLoader from "./StreamingLoader";

const TranscriptViewer = ({ audiobookOutline, fileID, userID, socketID = null, socket = null }) => {
    const [isOutlineOpen, setIsOutlineOpen] = useState(true);
    // pageTranscripts: { [page]: { text, segments } }
    const [pageTranscripts, setPageTranscripts] = useState({});
    // Array of audio URLs from each page.
    const [pageAudioURLs, setPageAudioURLs] = useState([]);
    // Current audio time (in seconds) from the combined audio.
    const [currentAudioTime, setCurrentAudioTime] = useState(0);
    const dispatch = useDispatch();
    const transcriptRef = useRef(null);
    const headingRefs = useRef({});
    const { serverIPs } = useServerIP();
    // Ref to control AudioPlayer (via AudioCombiner).
    const audioPlayerRef = useRef(null);
    // Ref for each transcript segment element.
    const transcriptSegmentRefs = useRef({});
    const [combinedDuration, setCombinedDuration] = useState(0);
    const currentPageAudiobook = useSelector(state => state.settings.currentPageAudiobook);

    const addAudioURL = (newURL) => {
        setPageAudioURLs((prev) => [...prev, newURL]);
    };

    /**
     * Gather all pages from the outline, remove duplicates, and sort them.
     */
    const getAllPagesInOrder = () => {
        const pageSet = new Set();
        audiobookOutline.forEach((chapter) => {
            for (let p = chapter.start_page; p <= chapter.end_page; p++) {
                pageSet.add(p);
            }
        });
        return Array.from(pageSet).sort((a, b) => a - b);
    };

    /**
     * Fetch a single page repeatedly until it returns abridged text, audio URL,
     * and optionally an aligned_transcript.
     */
    const fetchSinglePageWithRetry = (page) => {
        return new Promise((resolve) => {
            const attemptFetch = async () => {
                // console.log("FETCHING PAGE", {
                //     file_id: fileID.toString(),
                //     user_id: userID.toString(),
                //     page_number: page,
                //     socketio_stream_room: socketID,
                // });
                try {
                    const response = await axios.post(serverIPs.SERVER_IP + "/audiobooks/get_page", {
                        file_id: fileID.toString(),
                        user_id: userID.toString(),
                        page_number: page,
                        socketio_stream_room: socketID,
                    });
                    // console.log("FETCH PAGE RESPONSE", response);

                    const data = response.data?.message || {};
                    const abridgedText = data.abridged_page_text;
                    const audioUrl = data.audio_page_wav_url;
                    // If aligned_transcript exists, use it; otherwise, we only have abridged text.
                    if (abridgedText && audioUrl) {
                        if (data.aligned_transcript) {
                            setPageTranscripts((prev) => ({
                                ...prev,
                                [page]: { segments: data.aligned_transcript },
                            }));
                        } else {
                            setPageTranscripts((prev) => ({
                                ...prev,
                                [page]: { text: abridgedText },
                            }));
                        }
                        addAudioURL(audioUrl);
                        // console.log("ADDED AUDIO URL", audioUrl);
                        dispatch(addAudioInAudiobook({ documentID: fileID, audioToAdd: audioUrl }));
                        resolve();
                    } else {
                        setTimeout(attemptFetch, 5000);
                    }
                } catch (error) {
                    console.error("Error fetching page", page, error);
                    setTimeout(attemptFetch, 5000);
                }
            };
            attemptFetch();
        });
    };

    const fetchSinglePageWithRetryAligned = (page) => {
        return new Promise((resolve) => {
            const attemptFetch = async () => {
                console.log("FETCHING PAGE ALIGNED", {
                    file_id: fileID.toString(),
                    user_id: userID.toString(),
                    page_number: page,
                    socketio_stream_room: socketID,
                });
                try {
                    const response = await axios.post(serverIPs.SERVER_IP + "/audiobooks/get_page", {
                        file_id: fileID.toString(),
                        user_id: userID.toString(),
                        page_number: page,
                        socketio_stream_room: socketID,
                    });
                    console.log("FETCH PAGE RESPONSE ALIGNED", response);
                    const data = response.data?.message || {};
                    const abridgedText = data.abridged_page_text;
                    const audioUrl = data.audio_page_wav_url;
                    // If aligned_transcript exists, use it; otherwise, we only have abridged text.
                    if (abridgedText && audioUrl && data.aligned_transcript) {
                        setPageTranscripts((prev) => ({
                            ...prev,
                            [page]: { segments: data.aligned_transcript },
                        }));
                        resolve();
                    } else {
                        setTimeout(attemptFetch, 4000);
                    }
                } catch (error) {
                    console.error("Error fetching page", page, error);
                    setTimeout(attemptFetch, 4000);
                }
            };
            attemptFetch();
        });
    };

    /**
     * Fetch pages sequentially in order.
     */
    const fetchAllPagesSequentially = async () => {
        const pagesInOrder = getAllPagesInOrder();
        for (let i = 0; i < pagesInOrder.length; i++) {
            const page = pagesInOrder[i];
            if (!pageTranscripts[page]) {
                await fetchSinglePageWithRetry(page);
            }
        }
    };

    useEffect(() => {
        // console.log("Updated AUDIO URLS", pageAudioURLs);
    }, [pageAudioURLs]);

    useEffect(() => {
        fetchAllPagesSequentially();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollToHeading = (heading) => {
        if (headingRefs.current[heading]) {
            headingRefs.current[heading].scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    // When a transcript sentence is clicked, seek the audio to that segment’s global start.
    const handleSegmentClick = (globalStart) => {
        if (audioPlayerRef.current && typeof audioPlayerRef.current.seekTo === "function") {
            audioPlayerRef.current.seekTo(globalStart);
        }
        // Ensure audio plays.
        if (typeof audioPlayerRef.current.playAudio === "function") {
            audioPlayerRef.current.playAudio();
        }
    };

    // Build transcript elements with global timestamps.
    // For pages with aligned transcript, compute the global start/end by adding a cumulative offset.
    const pagesOrdered = getAllPagesInOrder();
    let cumulativeOffset = 0;
    const transcriptElements = [];
    pagesOrdered.forEach((page) => {
        const pageData = pageTranscripts[page];
        if (!pageData) return; // Skip pages not yet loaded.
        if (pageData.segments) {
            // For pages with an aligned transcript, each segment's times are relative to this page.
            pageData.segments.forEach((segment, index) => {
                transcriptElements.push({
                    id: `page-${page}-seg-${index}`,
                    text: segment.text,
                    // Global times are computed as cumulativeOffset + segment's relative time.
                    globalStart: cumulativeOffset + segment.start_time,
                    globalEnd: cumulativeOffset + segment.end_time,
                });
            });
            // Update cumulativeOffset based on this page's duration (assume last segment's end_time).
            const lastSegment = pageData.segments[pageData.segments.length - 1];
            cumulativeOffset += lastSegment.end_time;
        } else if (pageData.text) {
            // If no aligned transcript, render the whole text without timing.
            transcriptElements.push({
                id: `page-${page}`,
                text: pageData.text,
                globalStart: null,
                globalEnd: null,
            });
        }
    });

    // Determine active segment: one with timing and currentAudioTime within its bounds.
    // Compute total transcript duration (from cumulativeOffset) and adjust each segment’s timestamps.
    const totalTranscriptDuration = cumulativeOffset;
    let adjustedTranscriptElements = transcriptElements;
    if (combinedDuration && totalTranscriptDuration > 0) {
        const ratio = combinedDuration / totalTranscriptDuration;
          adjustedTranscriptElements = transcriptElements.map((seg) => ({
                 ...seg,
                 globalStart: seg.globalStart != null ? seg.globalStart * ratio : null,
                 globalEnd: seg.globalEnd != null ? seg.globalEnd * ratio : null,
               }));
         }

         const activeSegmentId = adjustedTranscriptElements.find(
           (seg) =>
         seg.globalStart != null &&
         seg.globalEnd != null &&
         currentAudioTime >= seg.globalStart &&
         currentAudioTime < seg.globalEnd
     )?.id;

    // Auto-scroll the active transcript block into view.
    useEffect(() => {
        if (
            activeSegmentId &&
            transcriptSegmentRefs.current[activeSegmentId]
        ) {
            transcriptSegmentRefs.current[activeSegmentId].scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [activeSegmentId]);

    // Maintain a ref to track finished pages.
    const finishedPages = useRef(new Set());

    // Helper: check that all pages lower than the given page (starting at 1)
    // are finished. (Adjust this if your page numbering or logic is different.)
    const arePreviousPagesFinished = (page) => {
        for (let p = 1; p < page; p++) {
            if (!finishedPages.current.has(p)) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        // socketio.emit('transcribe_audio_for_pages', {'progress': f"Finished creating aligned transcripts for pages {pages}"}, room=socketio_stream_room)
        socket.on('transcribe_audio_for_pages', function (data) {
            if (data.progress.includes("Finished creating aligned transcripts for pages")){
                console.log("TRANSCRIPT VIEWER UPDATES", data);
                // Extract pages from the progress message.
                const match = data.progress.match(/\[(.*?)\]/);
                if (match) {
                    const pagesStr = match[1]; // e.g. "1, 2, 4, 5, 8, 9, 11, 13" or "3"
                    let pages = pagesStr
                        .split(",")
                        .map(s => parseInt(s.trim(), 10))
                        .sort((a, b) => a - b);
                    // Filter out pages that have already finished.
                    pages = pages.filter(page => !finishedPages.current.has(page));
                    // Process pages sequentially.
                    (async () => {
                        for (const page of pages) {
                            // Wait until all previous pages are finished.
                            while (!arePreviousPagesFinished(page)) {
                                await new Promise(r => setTimeout(r, 1000));
                            }
                            // Fetch this page aligned transcript (retrying every 5 sec until finished).
                            await fetchSinglePageWithRetryAligned(page);
                            // Mark this page as finished.
                            finishedPages.current.add(page);
                        }
                    })();
                }
            }
        });


        return () => {
            console.log("Socket disconnecting", socket)
            socket.disconnect();
        }
    }, []);

    return (
        <Box display="flex" height="100%">
            {/* Sidebar Outline Panel */}
            <StreamingLoader message="Loading the audio for page 2" />
            <Box
                width={isOutlineOpen ? "250px" : "50px"}
                transition="width 0.3s ease-in-out"
                bg={useColorModeValue("gray.100", "gray.700")}
                p={2}
                boxShadow="md"
                minWidth="50px"
                overflow="visible"
                position="relative"
            >
                <HStack justify="space-between">
                    {isOutlineOpen && <Text fontWeight="bold" ml={2}>Outline</Text>}
                    <IconButton
                        aria-label="Toggle Sidebar"
                        icon={isOutlineOpen ? <FaChevronLeft /> : <FaChevronRight />}
                        size="sm"
                        onClick={() => setIsOutlineOpen(!isOutlineOpen)}
                    />
                </HStack>
                <VStack align="start" spacing={2} mt={4} position="relative">
                    {audiobookOutline.map((chapter, index) => (
                        <OutlineButton
                            key={index}
                            heading={chapter.title}
                            onClick={() => scrollToHeading(chapter.title)}
                        />
                    ))}
                </VStack>
            </Box>

            {/* Main Transcript View */}
            {/*<Box flex="1" overflowY="auto" p={6} ref={transcriptRef} bg="white">*/}
            {/*    <VStack align="start" spacing={4}>*/}
            {/*        {adjustedTranscriptElements.map((seg) => (*/}
            {/*            <Text*/}
            {/*                key={seg.id}*/}
            {/*                ref={(el) => (transcriptSegmentRefs.current[seg.id] = el)}*/}
            {/*                whiteSpace="pre-wrap"*/}
            {/*                p={2}*/}
            {/*                borderRadius="md"*/}
            {/*                _hover={{ bg: seg.globalStart != null && seg.id !== activeSegmentId*/}
            {/*                        ? "yellow.100" : undefined }}*/}
            {/*                cursor={seg.globalStart != null ? "pointer" : "default"}*/}
            {/*                bg={seg.id === activeSegmentId ? "yellow.200" : "transparent"}*/}
            {/*                onClick={() =>*/}
            {/*                    seg.globalStart != null && handleSegmentClick(seg.globalStart)*/}
            {/*                }*/}
            {/*            >*/}
            {/*                {seg.text}*/}
            {/*            </Text>*/}
            {/*        ))}*/}
            {/*    </VStack>*/}
            {/*</Box>*/}
            <Box flex="1" overflowY="auto" p={6} ref={transcriptRef} bg="white">
                <VStack align="start" spacing={4}>
                    {audiobookOutline.map((chapter) => (
                        <Box key={chapter.title} width="100%">
                            <Text
                                ref={(el) => (headingRefs.current[chapter.title] = el)}
                                fontSize="xl"
                                fontWeight="bold"
                                color="orange.500"
                                mb={2}
                            >
                                {chapter.title} (Pages {chapter.start_page} – {chapter.end_page})
                            </Text>
                            {Array.from(
                                { length: chapter.end_page - chapter.start_page + 1 },
                                (_, idx) => {
                                    const pageNum = chapter.start_page + idx;
                                    if (!pageTranscripts[pageNum]) {
                                        return (
                                            <SkeletonText key={pageNum} width="100%" spacing="4" />
                                        );
                                    } else if (pageTranscripts[pageNum].segments) {
                                        // Compute cumulative offset for pageNum from all previous pages that have aligned transcript.
                                        let cumulativeOffset = 0;
                                        const allPages = getAllPagesInOrder();
                                        allPages.forEach((p) => {
                                            if (p < pageNum && pageTranscripts[p] && pageTranscripts[p].segments) {
                                                const segs = pageTranscripts[p].segments;
                                                cumulativeOffset += segs[segs.length - 1].end_time;
                                            }
                                        });
                                        // Calculate total transcript duration from all pages (for scaling).
                                        let totalDuration = 0;
                                        allPages.forEach((p) => {
                                            if (pageTranscripts[p] && pageTranscripts[p].segments) {
                                                const segs = pageTranscripts[p].segments;
                                                totalDuration += segs[segs.length - 1].end_time;
                                            }
                                        });
                                        const ratio = combinedDuration && totalDuration > 0
                                            ? combinedDuration / totalDuration
                                            : 1;
                                        return (
                                            <VStack key={pageNum} align="start" spacing="2">
                                                {pageTranscripts[pageNum].segments.map((segment, i) => {
                                                    const globalStart = (cumulativeOffset + segment.start_time) * ratio;
                                                    const globalEnd = (cumulativeOffset + segment.end_time) * ratio;
                                                    const isActive =
                                                        currentAudioTime >= globalStart &&
                                                        currentAudioTime < globalEnd;
                                                    return (
                                                        <Text
                                                            key={`page-${pageNum}-seg-${i}`}
                                                            ref={(el) =>
                                                                (transcriptSegmentRefs.current[
                                                                    `page-${pageNum}-seg-${i}`
                                                                    ] = el)
                                                            }
                                                            whiteSpace="pre-wrap"
                                                            p={2}
                                                            borderRadius="md"
                                                            cursor="pointer"
                                                            bg={isActive ? "yellow.200" : "transparent"}
                                                            _hover={{ bg: isActive ? undefined : "yellow.100" }}
                                                            onClick={() => handleSegmentClick(globalStart)}
                                                        >
                                                            {segment.text}
                                                        </Text>
                                                    );
                                                })}
                                            </VStack>
                                        );
                                    } else if (pageTranscripts[pageNum].text) {
                                        return (
                                            <SkeletonText key={pageNum} isLoaded={true} width="100%" spacing="4">
                                                <Text whiteSpace="pre-wrap">
                                                    {pageTranscripts[pageNum].text}
                                                </Text>
                                            </SkeletonText>
                                        );
                                    }
                                }
                            )}
                        </Box>
                    ))}
                </VStack>
            </Box>

            {/* Audio Combiner/Player */}
            <Box
                position="absolute"
                bottom="0px"
                left="50%"
                transform="translateX(-50%)"
                zIndex="1000"
                width="auto"
            >
                <AudioCombiner
                    pageAudioURLs={pageAudioURLs}
                    onTimeUpdate={setCurrentAudioTime}
                    onDuration={setCombinedDuration}
                    forwardedRef={audioPlayerRef}
                />
            </Box>
        </Box>
    );
};

export default TranscriptViewer;
