import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    HStack,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure, useToast, Tooltip
} from "@chakra-ui/react";
import lightTheme from "../../utils/styles";
import React, { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
    setCurrentGeneratePDF,
    setCurrentGeneratePDFPage,
    setFilesInDocumentGeneration,
    setIsGeneratePDFVisible
} from "../../redux/reducers";
import DeleteScreenMultidocSource from "../../components/Modals/DeleteModal/DeleteScreenMultidocSource";
import { addActiveSourcesMultidoc, removeActiveSourcesMultidoc } from "../../utils/multi-doc-helpers";
import { useTranslation } from "react-i18next";
import { setIsViewSourcesModalOpen } from "../../redux/reducers";
import {useServerIP} from "../../redux/ServerIPContext";
export const SideBarFileUploadButton = ({ icon, label, file, generation }) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch();
    const { isOpen: isOpenDeleteMultidocSource, onOpen: onOpenDeleteMultidocSource, onClose: onCloseDeleteMultidocSource } = useDisclosure();
    const currentGenerationSessionToken = useSelector(state => state.settings.currentGenerationSessionToken);
    const [isChecked, setIsChecked] = useState(file.isActive);
    const toast = useToast();
    const { t } = useTranslation();
    const isGeneratePDFVisible = useSelector(state => state.settings.isGeneratePDFVisible);
    const [isLoading, setIsLoading] = useState(false);
    const { serverIPs, setServerIPs, isDev } = useServerIP();

    const makeActive = async () => {
        setIsLoading(true);
        try {
            const r = await addActiveSourcesMultidoc(currentGenerationSessionToken, localStorage.getItem("userID"), [file.id], serverIPs.SERVER_IP_MULTIDOC);
            if (r.status === 200) {
                setIsChecked(true);
                const files = generation.files.map((f) =>
                    f.id === file.id ? { ...f, isActive: true } : f
                );
                dispatch(setFilesInDocumentGeneration({ documentID: generation.id, newFiles: files }));
            } else {
                toast({
                    title: t('Oops, something went wrong. This source is still inactive.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setIsChecked(false);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const makeInactive = async () => {
        setIsLoading(true);
        try {
            const r = await removeActiveSourcesMultidoc(currentGenerationSessionToken, localStorage.getItem("userID"), file.id, serverIPs.SERVER_IP_MULTIDOC);
            if (r.status === 200) {
                setIsChecked(false);
                const files = generation.files.map((f) =>
                    f.id === file.id ? { ...f, isActive: false } : f
                );
                dispatch(setFilesInDocumentGeneration({ documentID: generation.id, newFiles: files }));
            } else {
                toast({
                    title: t('Oops, something went wrong. This source is still active.'),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setIsChecked(true);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Button
            variant="ghost"
            w="100%"
            justifyContent="start"
            _hover={{ bg: lightTheme.colors.lightGray }}
            _active={{ bg: lightTheme.colors.lightGray }}
            borderRadius={0}
            py={7}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                console.log('file ACTIVE IN SIDE PANEL', file);

                if (isGeneratePDFVisible) {
                    // dispatch(setIsViewSourcesModalOpen(false));
                    dispatch(setIsGeneratePDFVisible(false));
                    dispatch(setCurrentGeneratePDFPage(1));
                    dispatch(setCurrentGeneratePDF(file.url))
                    dispatch(setIsGeneratePDFVisible(true))
                } else {
                    // console.log("Here 2")
                    // dispatch(setIsViewSourcesModalOpen(false));
                    dispatch(setCurrentGeneratePDFPage(1));
                    dispatch(setCurrentGeneratePDF(file.url))
                    dispatch(setIsGeneratePDFVisible(true))
                }
                dispatch(setIsViewSourcesModalOpen(false));


                // set file.id
                // navigate(`/pdf/${document.id}`)

            }}
        >
            <div style={{ width: '100%', display: 'block' }}>
                <HStack direction="row" width="100%" justifyContent={'space-between'}>
                    <HStack justifyContent={'flex-start'} spacing={4} width={'90%'}>
                        <Menu>
                            <MenuButton onClick={(e) => e.stopPropagation()} // Prevent propagation
                                _hover={{ bg: lightTheme.colors.darkGray }}
                                _active={{ bg: "transparent" }} borderRadius={20} py={1.5}>
                                <Icon as={isHovered ? FaEllipsisV : icon} flexShrink={0} />
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={(e) => {
                                    console.log("CLICKED REMOVE SOURCE");
                                    e.stopPropagation();
                                    onOpenDeleteMultidocSource();
                                }}>Delete Source</MenuItem>
                                {/* <MenuItem onClick={() => {

                                }}>Rename Source</MenuItem> */}
                            </MenuList>
                        </Menu>
                        <Box
                            fontSize="sm"
                            fontWeight="normal"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                        >
                            {label}
                        </Box>
                    </HStack>
                    <Box
                        mt={0.8}
                        onClick={(e) => e.stopPropagation()} // Prevent propagation for the checkbox
                    >
                        <Tooltip hasArrow label={isChecked ? t("Click to deactivate. Then this source won't be cited.") : t("Click to activate. Then this source will be cited.")} bg='black' color="white" placement="top">
                            <span>
                                <Checkbox
                                    isChecked={isChecked}
                                    onChange={() => {
                                        if (!isLoading) {
                                            if (isChecked) {
                                                makeInactive();
                                            } else {
                                                makeActive();
                                            }
                                        }
                                    }}
                                    size="lg"
                                    isDisabled={isLoading}
                                    borderColor="gray.400"
                                />
                            </span>
                        </Tooltip>

                    </Box>
                </HStack>
            </div>
            {/*const DeleteScreenMultidocSource = ({ isOpenDeleteMultidocSource, onCloseDeleteMultidocSource, title,*/}
            {/*userID, fileID, multidocID, multidocSessionID, }) => {*/}
            <DeleteScreenMultidocSource isOpenDeleteMultidocSource={isOpenDeleteMultidocSource} onCloseDeleteMultidocSource={onCloseDeleteMultidocSource}
                title={label} userID={localStorage.getItem("userID")} fileID={file.id}
                multidocID={generation.id} multidocSessionID={currentGenerationSessionToken} generation={generation}/>
        </Button>
    );
};
