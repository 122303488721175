import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import {
    Box,
    Button,
    IconButton,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
    HStack,
    VStack
} from "@chakra-ui/react";
import { FaPlay, FaPause, FaForward, FaBackward } from "react-icons/fa";
import { IoPause, IoPlay } from "react-icons/io5";
import SpeedControl from "./SpeedControl";

const AudioPlayer = forwardRef(({ audioSrc, duration = 3600, onTimeUpdate }, ref) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [speed, setSpeed] = useState(1.0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isSpeedOpen, setIsSpeedOpen] = useState(false);
    const audioRef = useRef(null);

    // ✅ Positioning state
    const [position, setPosition] = useState({ x: -192, y: -105 });
    const playerRef = useRef(null);
    const draggingRef = useRef(false);
    const offsetRef = useRef({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        draggingRef.current = true;
        offsetRef.current = {
            x: e.clientX - position.x,
            y: e.clientY - position.y
        };
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (!draggingRef.current) return;
        setPosition({
            x: e.clientX - offsetRef.current.x,
            y: e.clientY - offsetRef.current.y
        });
    };

    const handleMouseUp = () => {
        draggingRef.current = false;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    // Audio logic (unchanged)
    useImperativeHandle(ref, () => ({
        seekTo: (time) => {
            if (audioRef.current) {
                audioRef.current.currentTime = time;
                setCurrentTime(time);
            }
        },
        playAudio: () => {
            if (audioRef.current) {
                audioRef.current.play();
                setIsPlaying(true);
            }
        },
    }));

    useEffect(() => {
        let prevTime = 0;
        let wasPlaying = false;
        if (audioRef.current) {
            prevTime = audioRef.current.currentTime;
            wasPlaying = !audioRef.current.paused;
            audioRef.current.pause();
        }
        const audio = new Audio(audioSrc);
        audioRef.current = audio;
        audio.playbackRate = speed;

        const updateTime = () => {
            setCurrentTime(audio.currentTime);
            if (typeof onTimeUpdate === "function") {
                onTimeUpdate(audio.currentTime);
            }
        };
        audio.addEventListener("timeupdate", updateTime);

        audio.addEventListener("loadedmetadata", () => {
            audio.playbackRate = speed;
            if (audio.duration > prevTime) {
                audio.currentTime = prevTime;
            }
            if (wasPlaying) {
                audio.play();
                setIsPlaying(true);
            }
        });

        return () => {
            audio.removeEventListener("timeupdate", updateTime);
            audio.pause();
            audio.src = "";
            audio.load();
        };
    }, [audioSrc]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = speed;
        }
    }, [speed]);

    const togglePlay = () => {
        if (!audioRef.current) return;
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const seek = (time) => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = time;
        setCurrentTime(time);
    };

    const fastForward = () => {
        seek(Math.min(currentTime + 15, duration));
    };

    const rewind = () => {
        seek(Math.max(currentTime - 15, 0));
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <Box
            ref={playerRef}
            position="absolute"
            top={`${position.y}px`}
            left={`${position.x}px`}
            width={{ base: "90%", md: "400px" }}
            bg="black"
            color="white"
            borderRadius={22}
            px={4}
            py={3}
            boxShadow="lg"
            zIndex="1000"
            cursor="default"
        >
            <SpeedControl speed={speed} setSpeed={setSpeed} isOpen={isSpeedOpen}
                          duration={duration} onClose={() => setIsSpeedOpen(false)} />
            <VStack spacing={1} width="100%">
                <HStack justify="space-between" width="100%">
                    <Text fontSize={13} color={'white'} fontWeight={'500'}>{formatTime(currentTime)}</Text>
                    <Text fontSize={13} color={'white'} fontWeight={'500'}>{formatTime(duration)}</Text>
                </HStack>
                <Slider
                    aria-label="seek-bar"
                    min={0}
                    max={duration}
                    value={currentTime}
                    onChange={seek}
                    focusThumbOnChange={false}
                >
                    <SliderTrack bg="gray.600">
                        <SliderFilledTrack bg="blue.400" />
                    </SliderTrack>
                    <SliderThumb boxSize={3} />
                </Slider>
                <Box display="flex" alignItems="center" flexDirection="row" width="100%" mb={1} mt={2}>
                    <Box
                        onMouseDown={handleMouseDown}
                        cursor="grab"
                        alignSelf="flex-start"
                        position={'absolute'}
                        bottom={2}
                        left={2}
                        px={2}
                        py={1}
                        fontSize="xs"
                        bg="gray.800"
                        color="gray.300"
                        borderRadius="md"
                        userSelect="none"
                    >
                        Drag
                    </Box>
                    <Box position="absolute" left="50%" transform="translateX(-50%)">
                        <HStack spacing={2} align="center">
                            <IconButton
                                icon={<FaBackward />}
                                aria-label="Rewind 15s"
                                onClick={rewind}
                                size="md"
                                variant="ghost"
                                color="white"
                                _hover={{ bg: "gray.700" }}
                            />
                            <IconButton
                                icon={isPlaying ? <IoPause /> : <IoPlay />}
                                aria-label={isPlaying ? "Pause" : "Play"}
                                onClick={togglePlay}
                                size="md"
                                color="black"
                                bg="white"
                                borderRadius="full"
                                boxSize="47px"
                                pl={isPlaying ? 0.8 : 1}
                                fontSize={25}
                                _hover={{ bg: "gray.100" }}
                                _active={{ bg: "gray.200" }}
                                boxShadow="md"
                            />
                            <IconButton
                                icon={<FaForward />}
                                aria-label="Fast Forward 15s"
                                onClick={fastForward}
                                size="md"
                                variant="ghost"
                                color="white"
                                _hover={{ bg: "gray.700" }}
                            />
                        </HStack>
                    </Box>
                    <Box ml="auto">
                        <Button
                            onClick={() => setIsSpeedOpen(!isSpeedOpen)}
                            color={"white"}
                            bg={"gray.900"}
                            borderRadius="full"
                            boxSize="47px"
                            fontSize={14.5}
                            _hover={{ bg: "gray.800" }}
                            _active={{ bg: "gray.700" }}
                            borderColor={'gray'}
                            borderWidth={0.25}
                        >
                            {speed.toFixed(1)}x
                        </Button>
                    </Box>
                </Box>
            </VStack>
        </Box>
    );
});

export default AudioPlayer;
