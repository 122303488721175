import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const StreamingLoader = ({ message }) => {
    const [dots, setDots] = useState(".");

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => (prev === "..." ? "." : prev + "."));
        }, 500);
        return () => clearInterval(interval);
    }, []);

    const fullMessage = `${message}...`;

    return (
        <MotionBox
            position="fixed"
            bottom="20px"
            right="20px"
            px={5}
            py={3}
            borderRadius="md"
            bg="gray.900"
            color="white"
            boxShadow="xl"
            zIndex={1500}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            fontFamily="monospace"
            fontSize="sm"
        >
            {/* Phantom full-length message to lock width */}
            <Text
                opacity={0}
                pointerEvents="none"
                height={0}
                whiteSpace="nowrap"
                fontSize="sm"
                fontFamily="monospace"
            >
                {fullMessage}
            </Text>

            {/* Visible animated text */}
            <Text whiteSpace="nowrap">
                {message}
                {dots}
            </Text>
        </MotionBox>
    );
};

export default StreamingLoader;
