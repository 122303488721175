import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useRef, useState, useEffect } from "react";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";

export const SuggestedQuestionsCarousel = ({ suggestedQuestions, setCurrentChatInput }) => {
    const carouselRef = useRef(null);
    const [scrollAtStart, setScrollAtStart] = useState(true);
    const [scrollAtEnd, setScrollAtEnd] = useState(false);

    // Scroll event handler to update fade visibility
    useEffect(() => {
        const handleScroll = () => {
            if (carouselRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
                setScrollAtStart(scrollLeft === 0);
                setScrollAtEnd(scrollLeft + clientWidth >= scrollWidth);
            }
        };

        const currentRef = carouselRef.current;
        currentRef?.addEventListener("scroll", handleScroll);
        return () => currentRef?.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
        }
    };

    if (!suggestedQuestions || suggestedQuestions.length === 0) return null;

    return (
        <Box position="relative" width="100%" overflow="hidden" pl={2} pr={2} pt={1} pb={2} borderRadius="full">
            {/* Left Fade Effect */}
            {!scrollAtStart && (
                <Box
                    position="absolute"
                    left="0"
                    top="0"
                    bottom="0"
                    width="10px"
                    pointerEvents="none"
                    background="linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
                    zIndex={1}
                    borderRadius="full"
                />
            )}

            {/* Left Arrow */}
            <IconButton
                icon={<AiOutlineLeft size={18} />}
                onClick={scrollLeft}
                position="absolute"
                left="5px"
                top="50%"
                transform="translateY(-50%)"
                zIndex={2}
                borderRadius="full"
                bg="white"
                boxShadow="md"
                _hover={{ bg: "gray.200" }}
                aria-label="Scroll Left"
                size="sm"
                opacity={scrollAtStart ? 1 : 1}
            />

            {/* Scrollable Questions */}
            <Flex
                ref={carouselRef}
                overflowX="auto"
                whiteSpace="nowrap"
                scrollBehavior="smooth"
                align="center"
                borderRadius="full"
                css={{
                    "&::-webkit-scrollbar": { display: "none" },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none",
                    maskImage:
                        "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 0))",
                    WebkitMaskImage:
                        "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 0))",
                }}
                px={4} // Space for arrows
                mx={4}
                py={1}
                bg="white"
                border="0px solid #E2E8F0"
            >
                {suggestedQuestions.map((question, index) => (
                    <Box
                        key={index}
                        flex="0 0 auto"
                        px={4}
                        py={2}
                        bg="gray.100"
                        borderRadius="full"
                        cursor="pointer"
                        _hover={{ bg: "gray.200" }}
                        onClick={() => setCurrentChatInput(question)}
                        transition="background 0.2s"
                        mx={1.5}
                    >
                        <Text fontSize="sm" whiteSpace="nowrap">{question}</Text>
                    </Box>
                ))}
            </Flex>

            {/* Right Arrow */}
            <IconButton
                icon={<AiOutlineRight size={18} />}
                onClick={scrollRight}
                position="absolute"
                right="5px"
                top="50%"
                transform="translateY(-50%)"
                zIndex={2}
                borderRadius="full"
                bg="white"
                boxShadow="md"
                _hover={{ bg: "gray.200" }}
                aria-label="Scroll Right"
                size="sm"
                opacity={scrollAtEnd ? 1 : 1}
            />

            {/* Right Fade Effect */}
            {!scrollAtEnd && (
                <Box
                    position="absolute"
                    right="0"
                    top="0"
                    bottom="0"
                    width="10px"
                    pointerEvents="none"
                    background="linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
                    zIndex={1}
                    borderRadius="full"
                />
            )}
        </Box>
    );
};
